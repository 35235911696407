export const en = {
  location: 'Location',
  stores: 'Stores',
  store: 'Store',
  createStore: 'Add Store',
  phoneNumber: 'Phone Number',
  editStore: 'Edit Store',
  city: 'City',
  startTime: 'Start Time',
  endTime: 'End Time',
  addPOS: 'Add POS Device',
  categorySuccess: 'Category Added Successfully!',
  categoryUpdated: 'Category Updated Successfully!',
  categoryNotUpdated: 'Unable To Update Category',
  categoryError: 'Unable To Add Category',
  categoryDeleted: 'Category Deleted Successfully!',
  categoryNotDeleted: 'Unable To Delete Category',
  useGlobalSettings: 'Use Global Settings',
  globalSettingsHelper: 'if this is checked, this store will inherit the',
  globalSettingsRedirect: 'settings you set globally.',
  save: 'Save',
  country: 'Country',
  options: 'Options',
  neighbourhood: 'Neighbourhood',
  acceptingOrders: 'Accepting Orders',
  notAcceptingOrders: 'Not Accepting Orders',
  settings: 'Settings',
  edit: 'Edit',
  main: 'Main',
  storeHours: 'Store Hours',
  noDeviceMsg:
    'You have no devices. To receive orders for this store, you must add a device',
  storeType: 'Store Type',
  coffeeShop: 'Coffee Shop',
  restaurant: 'Restaurant',
  aboutPlaceHolder: 'Italian Restaurant',
  onlineCoffeeShop: 'Online Coffee Shop',
  onlineRestaurant: 'Online Restaurant',
  foodTruck: 'Food Truck',
  coffeeShopTruck: 'Coffee Shop Truck',
  either: 'Either',
  searchLocation: 'Search Location',
  locateMe: 'Locate Me',
  addLocation: 'Add Location',
  storeUpdatedSuccessfully: 'Store Successfully Updated!',
  hideLocation: 'Hide this location from customers',
  hideLocationDesc:
    'This is a good option for busineseses operating from private locations (i.e. homes, apartments)',
  deleteStoreMsg:
    'will be deleted from your stores. This means the store will be permanently removed from the system.',
  storeDeletedSuccessfully: 'Store deleted!',
  storeAddedSuccessfully: 'Store added successfully',
  mustEnterLocation: 'Please enter a location for your store',
  storesSubtitle:
    'Provide digital menu or receive online orders via stores (i.e. Burger King)',
  notReceivingOrders: 'Not Receiving Orders',
  receiveOrders: 'Receive Orders',
  open: 'Open',
  closed: 'Closed',
  branchName: 'Branch Name',
  branchPlaceHolder: 'Downtown',
  emptyStoresPlaceHolder: 'Create your first store and start adding menu items',
};

export const ar: typeof en = {
  location: 'العنوان',
  stores: 'المتاجر',
  store: 'متجر',
  city: 'المدينة',
  startTime: 'بداية العمل',
  categoryDeleted: '',
  categoryUpdated: '',
  categoryNotDeleted: '',
  categorySuccess: '',
  categoryError: '',
  categoryNotUpdated: '',
  endTime: 'نهاية العمل',
  country: 'الدولة',
  useGlobalSettings: 'استخدم الأعدادت العامة',
  globalSettingsHelper: 'اذا تم تفعيل هذا الأختيار، سيتم استخدام',
  globalSettingsRedirect: 'الأعدادات العامة',
  createStore: 'اضف متجر',
  phoneNumber: 'رقم الهاتف',
  editStore: 'تعديل المتجر',
  options: 'الخيارات',
  addPOS: 'اضف منصة طلبات',
  save: 'احفظ',
  neighbourhood: 'المقاطعة',
  acceptingOrders: 'استلام طلبات',
  notAcceptingOrders: 'لا يستلم طلبات',
  settings: 'الأعدادات',
  edit: 'تعديل',
  main: 'ألاساسية',
  storeHours: 'ساعات العمل',
  noDeviceMsg:
    'لايوحد منصة طلبات لهذا المتجر. لاستلام طلبات، لابد من اضافة منصة الطلبات',
  storeType: 'نوع المتجر',
  coffeeShop: 'قهوة',
  restaurant: 'مطعم',
  aboutPlaceHolder: 'مطعم ايطالي',
  onlineCoffeeShop: 'مقهى اونلاين',
  onlineRestaurant: 'مطعم اون لاين',
  foodTruck: 'فود ترك',
  coffeeShopTruck: 'كوفي شوب ترك',
  either: 'اما',
  searchLocation: 'ابحث عن العنوان',
  locateMe: 'حدد موقعي',
  addLocation: 'اضف عنوان',
  storeUpdatedSuccessfully: 'تم تحديث المتجر بنجاح',
  hideLocation: 'اخفاء عنوان المتجر من العميل',
  hideLocationDesc:
    'هذا الخيار مناسب للمنشاًت الناتجة من مواقع خاصة مثل مقر السكن',
  deleteStoreMsg: '',
  storeDeletedSuccessfully: '',
  storeAddedSuccessfully: 'تم اضافة المتجر بنجاح',
  mustEnterLocation: 'الرجاء ادخال مقر أو عنوان للمتجر',
  storesSubtitle: 'قدم منيو الكتروني أو استلم طلبات عن طريق المتاجر',
  notReceivingOrders: 'لايستلم طلبات',
  receiveOrders: 'استلام الطلبات',
  open: 'مفتوح',
  closed: 'مغلق',
  branchName: 'اسم الفرع',
  branchPlaceHolder: 'العليا',
  emptyStoresPlaceHolder: 'قم بإنشأ متجرك وثم اضف معلومات قائمة الأصناف',
};
