import {
  createStyles,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { useAuthStore } from 'src/auth/hocs/useAuth';

const drawerWidth = 250;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mobileContent: {
      flex: 1,
      flexDirection: 'column',
      marginTop: 60,
      overflowX: 'hidden',
      padding: '10px 20px',
    },

    content: {
      flexGrow: 1,
      marginTop: 60,
      flex: 1,
      overflow: 'hidden',
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
  }),
);
export const ContentWrapper = ({ children }) => {
  const theme = useTheme();
  const classes = useStyles();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { isLoggedIn } = useAuthStore();
  return (
    <main
      className={` ${
        isLoggedIn
          ? isMobile
            ? classes.mobileContent
            : clsx(classes.content, {
                [classes.contentShift]: !isMobile,
              })
          : 'p-0'
      }`}>
      {children}
    </main>
  );
};
