import {
  createStyles,
  fade,
  InputBase,
  InputBaseProps,
  Theme,
  withStyles,
} from '@material-ui/core';
import React from 'react';

export const StyledTextField = withStyles((theme: Theme) =>
  createStyles({
    root: {
      'label + &': {
        marginTop: theme.spacing(2.5),
      },
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: '#fafafa',
      fontSize: 16,
      width: 'inherit',
      padding: '15px',
      boxShadow: 'rgba(0,0,0,0.14)',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      '&:focus': {
        boxShadow: `${fade(theme.palette.primary.main, 0.24)} 0 0 0 0.1rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  }),
)((props: InputBaseProps) => <InputBase {...props} />);
