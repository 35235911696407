export enum ReactQueryKey {
  OperatorDetailsKey = 'OperatorDetailsKey',
  OperatorStoresKey = 'OperatorStoresKey',
  OperatorDevicesKey = 'OperatorDevicesKey',
  AllOrdersKey = 'AllOrdersKey',
  AllDevicesKey = 'AllDevicesKey',
  AllStoresKey = 'AllStoresKey',
  AllMenusKey = 'AllMenusKey',
  AllMenuItemsKey = 'AllMenuItemsKey',
  AllMerchantsKey = 'AllMerchantsKey',
  AllLinks = 'AllLinks',
  AllCategories = 'AllCategories',
  AllCustomers = 'AllCustomers',
  Merchant = 'Merchant',
  Myself = 'Myself',
  Account = 'Account',
  Options = 'Options',
  FetchAccountUsage = 'FetchAccountUsage',
  fetchGlobalSettings = 'FetchGlobalSettings',
  updateGlobalSettings = 'UpdateGlobalSettings',
  Couriers = 'Couriers',
  SingleMenuItem = 'singleMenuItem',
  UpdateVersion = 'UpdateVersion',
}
