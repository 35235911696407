import {
  AppBar,
  Avatar,
  Box,
  Button,
  createStyles,
  IconButton,
  makeStyles,
  Menu,
  Theme,
  Toolbar,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import {
  ContactSupport,
  Language,
  Menu as MenuIcon,
  Storefront,
} from '@material-ui/icons';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { Link } from '@reach/router';
import React, { memo, useCallback } from 'react';
import Api from 'src/api/Api';
import { Images } from 'src/common/assets/images';
import { TopBarDropdown } from 'src/common/components/Page/components/';
import { useCurrentLanguage } from 'src/i18n/hooks/useCurrentLanguage';
import { t } from 'src/i18n/translate';
import { SupportedLanguage } from 'src/i18n/types';
import { TranslationDialog } from 'src/translations/components/TranslationsDialog/TranslationsDialog';
import { useTranslationsStore } from 'src/translations/hooks/useTranslations';
import { useFetchAccount } from 'src/user/hooks/useFetchAccount';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      background: 'rgba( 255, 255, 255, 0.25 )',
      boxShadow: '0 0px 4px 0 rgb(31 38 135 / 37%)',
      backdropFilter: 'blur( 16.5px )',
    },
    menuButton: {
      marginRight: theme.spacing(2),
      color: theme.palette.grey.A700,
    },
    logo: {
      width: 90,
      height: 40,
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    langButton: {
      minWidth: 50,
    },
  }),
);

interface Props {
  onMenuClick: () => void;
}

export const TopBar = memo<Props>(({ onMenuClick }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { merchant } = useFetchAccount();
  const { businessLogo } = merchant || {};
  const { language, setCurrentLanguage } = useCurrentLanguage();
  const { toggleDialog, isOpen } = useTranslationsStore();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const handleChangeLanguage = useCallback(
    (
      event: React.MouseEvent<HTMLElement, MouseEvent>,
      value: SupportedLanguage,
    ) => {
      if (value) {
        setCurrentLanguage(value);
      }
    },
    [setCurrentLanguage],
  );
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!Api.isUserLoggedIn()) {
    return null;
  }

  return (
    <AppBar position="fixed" className={classes.appBar} elevation={1}>
      <Toolbar>
        <IconButton
          color="inherit"
          edge="start"
          onClick={onMenuClick}
          className={classes.menuButton}>
          <MenuIcon />
        </IconButton>
        <Box
          display="flex"
          flexDirection="row"
          flexGrow={1}
          alignItems="center"
          justifyContent={isSmallScreen ? 'flex-end' : 'space-between'}>
          {!isSmallScreen && (
            <Box>
              <Link to={'/'}>
                <img className={classes.logo} src={Images.logo} alt="Foodbit" />
              </Link>
            </Box>
          )}

          <Box>
            {isSmallScreen ? (
              <IconButton onClick={toggleDialog}>
                <Language />
              </IconButton>
            ) : (
              <Box marginX={2} display="inline">
                <Button
                  onClick={toggleDialog}
                  startIcon={<Language />}
                  variant="outlined"
                  size="small">
                  {t('translations:translations')}
                </Button>
              </Box>
            )}

            <ToggleButtonGroup
              value={language}
              size="small"
              exclusive
              onChange={handleChangeLanguage}>
              <ToggleButton
                className={classes.langButton}
                value={SupportedLanguage.English}>
                EN
              </ToggleButton>
              <ToggleButton
                className={classes.langButton}
                value={SupportedLanguage.Arabic}>
                عربى
              </ToggleButton>
            </ToggleButtonGroup>
            <IconButton
              href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}?subject=Support Ticket`}>
              <ContactSupport color="disabled" />
            </IconButton>

            <IconButton onClick={handleClick}>
              <Avatar variant="rounded" src={businessLogo}>
                <Storefront />
              </Avatar>
            </IconButton>
            <Menu
              id="dropdown-menu"
              anchorEl={anchorEl}
              keepMounted
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={Boolean(anchorEl)}
              onClose={handleClose}>
              <TopBarDropdown />
            </Menu>
          </Box>
        </Box>
      </Toolbar>
      {isOpen && <TranslationDialog />}
    </AppBar>
  );
});
