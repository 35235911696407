import React from 'react';
import create from 'zustand';

export enum LinkType {
  Orders,
  QR_Link,
  Devices,
  Browse,
  Menu,
  Stores,
  StoreSettings,
  OrderSettings,
  MenuSettings,
  Links,
  QR_Codes,
  Settings,
  Customers,
  Account,
  Payments,
  Translations,
}

export interface SideMenuLink {
  key: LinkType;
  label: string;
  to?: string;
  icon?: React.ReactElement;
  subLinks?: SideMenuLink[];
}

export interface ExpandedSection {
  type: LinkType;
  isExpanded: boolean;
}

interface SideMenuStates {
  selectedItem: Opt<LinkType>;
  expandedSections: ExpandedSection[];
  setExpandedSections: (expandedSections: ExpandedSection[]) => void;
  setSelectedItem: (selectedItem: LinkType) => void;
}

const [useSideMenuStore] = create<SideMenuStates>(set => ({
  expandedSections: [],
  selectedItem: undefined,
  setSelectedItem: selectedItem => {
    set({ selectedItem });
  },
  setExpandedSections: (expandedSections: ExpandedSection[]) => {
    set({ expandedSections });
  },
}));

export const useSideMenu = () => useSideMenuStore();
