import './index.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { App } from 'src/App';
import { init } from 'src/i18n/init';
// import * as serviceWorker from 'src/serviceWorkerRegistration';

// if (process.env.NODE_ENV !== 'development') {
//   console.log = () => {};
//   console.warn = () => {};
//   console.error = () => {};
// }

init();

ReactDOM.render(<App />, document.getElementById('root'));

// serviceWorker.register();
