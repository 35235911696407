export const en = {
  dashboardSignup: 'Dashboard Signup',
  signup: 'Signup',
  login: 'Login',
  signupSuccessMessage: 'Signup Success! now you can login.',
  fillYourDetails: 'Please Fill in your details',
  alreadyRegistered: 'Already registered?',
};

export const ar: typeof en = {
  dashboardSignup: 'الاشتراك في لوحة التحكم',
  signup: 'الأشتراك',
  login: 'سجل الدخول',
  signupSuccessMessage: 'تم الأشتراك بنجاح',
  fillYourDetails: 'الرجاء ادخال معلوماتك',
  alreadyRegistered: 'لديك حساب؟',
};
