import { Divider, Grid } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Language as GlobeIcon } from '@material-ui/icons';
import React from 'react';
import { t } from 'src/i18n/translate';
import TranslationsForm from 'src/translations/components/TranslationsDialog/TranslationsForm';
import { useTranslationsStore } from 'src/translations/hooks/useTranslations';

export const TranslationDialog = () => {
  const { toggleDialog, isOpen } = useTranslationsStore();

  return (
    <Dialog open={isOpen} onClose={toggleDialog}>
      <DialogTitle>
        <Grid xs={12} container alignItems="flex-start">
          <GlobeIcon />
          <span className="ml-2">{t('translations:translations')}</span>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText>
          {t('translations:languageDialogHeadline')}
        </DialogContentText>
        <TranslationsForm showCancel />
      </DialogContent>
    </Dialog>
  );
};
