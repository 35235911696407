import { useQuery } from 'react-query';
import Api from 'src/api/Api';
import { ReactQueryKey } from 'src/common/enums';
import { MembershipType } from 'src/user/apiTypes';

export const useFetchAccount = () => {
  const { data, isFetching: loading, error } = useQuery(
    ReactQueryKey.Account,
    Api.fetchAccount,
    { staleTime: Infinity, retry: false },
  );

  return {
    account: data,
    membership: data?.membership,
    isPaid: data?.membership?.type === MembershipType.Paid,
    merchants: data?.merchants,
    merchant:
      data?.merchants && data.merchants.length > 0
        ? data.merchants[0]
        : undefined,
    loading,
    error,
  };
};
