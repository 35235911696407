import { useConfirm } from 'material-ui-confirm';
import { useLogout } from 'src/common/hooks/useLogout';
import { t } from 'src/i18n/translate';

export const useHandleLogout = () => {
  const { logout } = useLogout();
  const confirm = useConfirm();

  const handleLogout = async () => {
    try {
      await confirm({
        title: t('common:logoutConfirmTitle'),
        description: t('common:logoutConfirmMessage'),
        confirmationText: t('common:yes'),
        cancellationText: t('common:cancel'),
        confirmationButtonProps: {
          color: 'default',
        },
      });
      logout();
    } catch (e) {}
  };

  return { handleLogout };
};
