import { ErrorMessage } from '@hookform/error-message';
import {
  Box,
  createStyles,
  Grid,
  Hidden,
  InputLabel,
  Link as MUILink,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Link, navigate } from '@reach/router';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Images } from 'src/common/assets/images';
import { PrimaryButton } from 'src/common/components/Btn/PrimaryButton';
import { StyledTextField } from 'src/common/components/TextField/StyledTextField';
import { useLogin } from 'src/menu/hooks/useLogin';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#fff',
      height: '100vh',
      overflow: 'hidden',
    },
    logoContainer: {
      marginBottom: 10,
    },
    logo: {
      width: 152,
      height: 53,
    },
    subHeading: {
      fontSize: 18,
      color: theme.palette.primary.light,
      marginBottom: 18,
    },
    link: {
      fontSize: 14,
      fontWeight: 600,
      textDecoration: 'none',
      color: theme.palette.primary.light,
    },
  }),
);

export const LoginPage = () => {
  const classes = useStyles();

  const { login, loading, success } = useLogin();

  const { control, handleSubmit, errors } = useForm();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (success) {
      navigate('/');
    }
  }, [success]);

  const onSubmit = async (data: { username: string; password: string }) => {
    const { username, password } = data;
    await login(username, password);
  };

  return (
    <Grid container xs={12}>
      <div className={classes.root}>
        <Grid item xs={9} md={6}>
          <Box
            style={{
              padding: isMobile ? 0 : 140,
            }}
            display="flex"
            flexDirection="column">
            <div className={classes.logoContainer}>
              <img className={classes.logo} src={Images.logo} alt="Foodbit" />
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
              <InputLabel className="font-weight-600 mt-3 text-dark">
                Username
              </InputLabel>
              <Controller
                rules={{ required: true }}
                name="username"
                placeholder="Please enter your username"
                control={control}
                as={<StyledTextField fullWidth type="text" />}
              />
              <ErrorMessage
                message="Username is required"
                errors={errors}
                name="username"
                render={({ message }) => (
                  <p className="text-danger">{message}</p>
                )}
              />
              <InputLabel className="font-weight-600 mt-4  text-dark">
                Password
              </InputLabel>
              <Controller
                rules={{ required: true }}
                name="password"
                placeholder="Please enter your password"
                control={control}
                as={<StyledTextField fullWidth type="password" />}
              />
              <ErrorMessage
                message="Password is required"
                errors={errors}
                name="password"
                render={({ message }) => (
                  <p className="text-danger">{message}</p>
                )}
              />
              <Box py={2} display="flex">
                <Link className={classes.link} to="/forgot-password">
                  <Typography color="textSecondary">
                    Forgot Password?
                  </Typography>
                </Link>
              </Box>
              <PrimaryButton loading={loading}>Login</PrimaryButton>
              <Box
                py={2}
                display="flex"
                alignItems="baseline"
                justifyContent="flex-start">
                <Typography className="text-black mr-2 order-muted font-weight-600">
                  Dont have an account?
                </Typography>
                <MUILink
                  target="_blank"
                  className="green font-weight-600 text-decoration-none"
                  href="https://www.foodbit.io/signup">
                  Signup Here
                </MUILink>
              </Box>
            </form>
          </Box>
        </Grid>
        <Hidden smDown>
          <Grid item xs={8} md={6} className="login-bg h-100">
            <Box>
              <img
                className="h-100 w-100"
                src={Images.loginSignup}
                alt="signup"
              />
              <Typography
                className="text-center text-white font-weight-600 spacing"
                variant="h5">
                Login and keep tweaking until your reach 110% customer delight
              </Typography>
            </Box>
          </Grid>
        </Hidden>
      </div>
    </Grid>
  );
};
