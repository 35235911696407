import {
  Box,
  Checkbox,
  createStyles,
  Grid,
  IconButton,
  Link,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { RemoveCircleOutline } from '@material-ui/icons';
import React, { memo } from 'react';
import { t } from 'src/i18n/translate';
import { Language } from 'src/translations/uiTypes';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bgColor: {
      boxShadow: '0px 3px 25px #8795AB29',
      minHeight: theme.spacing(5),
    },
    padding: {
      paddingTop: theme.spacing(0.9),
    },
  }),
);

interface Props {
  lang: Opt<Language>;
  makeLanguagePrimary: (lang: Language | undefined) => void;
  handleToggleDisableLang: (lang: Language | undefined) => void;
  handleDelete: (lang: Language | undefined) => void;
  primary?: boolean;
  disabled?: boolean;
}

export const LanguageRow = memo<Props>(
  ({
    primary,
    disabled,
    makeLanguagePrimary,
    handleDelete,
    handleToggleDisableLang,
    lang,
  }) => {
    const classes = useStyles();
    return (
      <Grid item container xs={12} spacing={2}>
        <Box
          width={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center">
          {!primary ? (
            <Checkbox
              checked={!disabled}
              onChange={() => handleToggleDisableLang(lang)}
            />
          ) : (
            <Checkbox checked={true} disabled={true} />
          )}

          <Box
            width={1}
            display="flex"
            py={1}
            px={2}
            my={2}
            alignItems="center"
            justifyContent="space-between"
            className={classes.bgColor}>
            <Box display="flex" flexDirection="column">
              <Typography className="my-2 font-weight-600">
                {lang?.name}
              </Typography>
              {disabled ? (
                <Box>
                  <Typography variant="body2">
                    {t('common:disabled')}
                  </Typography>
                </Box>
              ) : primary ? (
                <Box>
                  <Typography variant="body2" color="textSecondary">
                    {t('translations:primary')}
                  </Typography>
                </Box>
              ) : (
                <Box>
                  <Link
                    color="secondary"
                    onClick={() => makeLanguagePrimary(lang)}>
                    {t('translations:makePrimary')}
                  </Link>
                </Box>
              )}
            </Box>
            {!primary && (
              <IconButton onClick={() => handleDelete(lang)}>
                <RemoveCircleOutline color="error" />
              </IconButton>
            )}
          </Box>
        </Box>
      </Grid>
    );
  },
);
