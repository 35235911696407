import { queryCache, useMutation } from 'react-query';
import Api from 'src/api/Api';
import { ReactQueryKey } from 'src/common/enums';
import { getQueryCacheFrom } from 'src/common/utils/reactQuery';
import { Languages } from 'src/translations/apiTypes';
import { Account } from 'src/user/apiTypes';
import { useFetchAccount } from 'src/user/hooks/useFetchAccount';

export const useUpdateLanguages = () => {
  const { merchant } = useFetchAccount();
  const { id: merchantId } = merchant || {};

  const [
    mutate,
    { isLoading: loading, isSuccess: success, error },
  ] = useMutation(Api.updateMerchant);

  const updateLanguages = (id: string, languages: Languages) => {
    const account = getQueryCacheFrom<Account>(ReactQueryKey.Account);

    if (account?.merchants) {
      const merchantPayload = {
        ...account.merchants[0],
        id,
        languages,
      };

      return mutate(merchantPayload, {
        onSuccess: () => {
          queryCache.invalidateQueries([
            merchantId,
            ReactQueryKey.AllStoresKey,
          ]);
          queryCache.invalidateQueries(ReactQueryKey.Account);
        },
      });
    }
  };

  return {
    updateLanguages,
    loading,
    error,
    success,
  };
};
