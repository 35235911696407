import { useQuery } from 'react-query';
import Api from 'src/api/Api';
import { ReactQueryKey } from 'src/common/enums';

export const useFetchVersion = () => {
  const { data, isFetching: loading, error } = useQuery(
    ReactQueryKey.UpdateVersion,
    Api.fetchVersion,
    {
      staleTime: Infinity,
      retry: false,
    },
  );

  return {
    data,
    loading,
    error,
  };
};
