export const en = {
  menu: 'Menu',
  menuCategories: 'Menu',
  type: 'Type',
  addCategory: 'Add Category',
  timeAvailability: 'Time Availability',
  editCategory: 'Edit Category',
  addItem: 'Add Item',
  groupName: 'Group Name',
  item: 'Item',
  code: 'POS Code',
  items: 'Items',
  menuItemSuccess: 'Menu Item Added Successfully!',
  menuError: 'Unable To Add Menu Item!',
  menuUpdateSuccess: 'Menu Item Updated Successfully!',
  menuUpdateError: 'Unable To Update Menu Item!',
  atleastOneItem: 'You need at least one category in an item.',
  deleteItemHelper: 'Item will be deleted permanently',
  options: 'Options',
  option: 'Option',
  general: 'General',
  newOptions: 'New Options',
  save: 'Save',
  editItem: 'Edit Item',
  addOption: 'Add Option',
  addOptions: 'Add Options',
  price: 'Price',
  calories: 'Calories',
  category: 'Category',
  theCategory: 'Category',
  alertMenuOption: 'Please Fill Details for Options',
  alertMenuOptionPrice: 'Please Fill The Menu Option Price',
  alertMenuItem: 'Please Fill Menu Item',
  selectFromFollowing: 'Select from the following',
  selectExistingOptions: 'Select from existing options',
  selectNewOptions: 'Enter new options',
  disableHide: 'Hide from Menu',
  tempUnavailable: 'Unavailable for stores',
  hidden: 'Hidden',
  name: 'Name',
  minimumSelections: 'Minimum Selections',
  maximumSelections: 'Maximum Selections',
  minSelectionPlaceholder: 'Enter Minimum Selections',
  maxSelectionPlaceholder: 'Enter Maximum Selections',
  required: 'Required',
  deleteOptions: 'Delete Options',
  deleteOption: 'Delete Option',
  deleteItem: 'Delete Item',
  optionAvailabilityStartTime: 'Availability Start Time',
  optionAvailabilityEndTime: 'Availability End Time',
  posCode: 'POS Code',
  deleteCategory: 'Delete Category',
  confirmDeleteOption: 'Are you sure you want to remove this option',
  deleteItemsFirst:
    'This category contains items! You can only delete an empty category',
  viewMenu: 'View Menu',
  createStoreFirst: 'To add menu items, please create a store first',
  addMenuItems: 'Add Menu Items',
  isRequired: 'Is Required',
  maximumNumberOfSelections: 'Maximum Number of Selections',
  minimumNumberOfSelections: 'Minimum Number of Selections',
  singleChoice: 'Single Selection',
  allowMultiple: 'Multiple',
  collapseAll: 'Collapse all',
  description: 'Description',
  optionSelected: 'Options Selected',
  selectCategory: 'Select Category',
  nameError: 'Name is required for ',
  unavailableInStores: 'Unavailable in these stores',
  unavailableInStoresTip:
    'If enabled, the item will be unavailable in the selected stores. If no stores are selected, the item will be unavailable across all stores ',
  tempUnavailableTip:
    'If enabled, the item will show as unavailable in the menu and customers will not be able to order it.' +
    ' This is useful when you want your customers to still see the item but it is out of stock',
};

export const ar: typeof en = {
  menu: 'قائمة الأصناف',
  menuCategories: 'القائمة',
  menuItemSuccess: '',
  menuError: '',
  menuUpdateSuccess: '',
  menuUpdateError: '',
  selectCategory: 'اختر القسم',
  optionSelected: 'الخيارات المختارة',
  minSelectionPlaceholder: 'الحد الأدنى للخيارات',
  maxSelectionPlaceholder: 'الحد الأعلى للخيارات',
  addOptions: 'اضف خيار',
  groupName: 'اسم المجموعة',
  type: 'النوع',
  addCategory: 'أضف قسم',
  addMenuItems: 'اضف صنف',
  editCategory: 'تحديث القسم',
  addItem: 'أضف صنف',
  atleastOneItem: 'لابد من تواجد قسم واحد (كحد ادني) لكل صنف',
  item: 'صنف',
  alertMenuOptionPrice: 'الرجاء إدخال السعر',
  alertMenuOption: 'الرجاء ادخال معلومات الأختيارات',
  alertMenuItem: 'الرجاء ادخال معلومات الصنف',
  general: 'عام',
  name: 'الاسم',
  timeAvailability: 'ساعات التواجد',
  code: 'رمز المنصة',
  deleteItemHelper: 'سيتم حذف الصنف بشكل نهائي',
  items: 'أصناف',
  options: 'أختيارات',
  option: 'الأختيار',
  newOptions: 'أضف مجموعة اختيارات',
  save: 'حفظ',
  editItem: 'تحديث الصنف',
  addOption: 'أضف اختيار',
  price: 'السعر',
  calories: 'السعرات الحرارية',
  category: 'قسم',
  theCategory: ' القسم',
  selectFromFollowing: 'اختر من التالي:',
  selectExistingOptions: 'اضافة اختيارات مدخلة من قبل',
  selectNewOptions: 'اضافة اختيارات جديدة',
  disableHide: 'اخفاء من القائمة',
  tempUnavailable: 'غير متواجد مؤقتاً',
  hidden: 'مخفي',
  minimumSelections: 'حد ادني للخيارات',
  maximumSelections: 'حد اعلى للخيارات',
  required: 'مطلوب',
  deleteOptions: 'احذف مجموعة الأختيارات',
  deleteOption: 'احذف الأختيارا',
  deleteItem: 'احذف الطبق',
  optionAvailabilityStartTime: 'بداية تقديم الاختيار',
  optionAvailabilityEndTime: 'نهاية تقديم الاختيار',
  posCode: 'كود يعرض في المنصة',
  deleteCategory: 'احذف القسم',
  confirmDeleteOption: 'هل انت متأكد تريد حذف الأختيار؟',
  viewMenu: 'اعرض المنيو',
  createStoreFirst: 'لإضافة محتويات المنيو، الرجاء انشأ متجر أولاً',
  deleteItemsFirst:
    'يوجد محتويات في هذا القسم. بإمكانك حذف القس اذا كان لايملك اي محتويات ',
  isRequired: 'اختيار اجباري',
  maximumNumberOfSelections: 'الحد الأعلى للاختيارات',
  minimumNumberOfSelections: 'الحد الأدنى للاختيارات',
  singleChoice: 'أختيار واحد فقط',
  allowMultiple: 'السماح بأكثر من أختيار',
  collapseAll: 'تصغير جميع الأقسام',
  description: 'الوصف',
  nameError: 'لابد من إدخال الأسم ل',
  unavailableInStores: 'المتاجر اللتي لاتحتوي على الصنف. اذا لم يتم الأختيار،',
  unavailableInStoresTip:
    'اذا تم تفعيل الأختيار، سيتم ايقاف الطلبات للصنف،' +
    ' اذا لم يتم اختيار المتاجر، الصنف سيكون غير متواجد في جميع المتاجر',
  tempUnavailableTip:
    'اذا تم التفعيل، سيتم اظهار علامة في المنيو تدل على ان الصنف غير متواجد ولايمكن للعملاء بطلبه.' +
    ' هذه الخاصية مفيدة اذا رغبت في ابلاغ عملائك بأن الصنف غير متواجد بشكل مؤقت فقط',
};
