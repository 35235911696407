export const en = {
  general: 'General',
  settings: 'Settings',
  settingsUpdated: 'Settings Updated Successfully!',
  settingsNotUpdated: 'Unable To Update Settings',
  openTab: 'Open/Closed Tab',
  openTabOpen: 'Open Tab (Customer places multiple orders before payment)',
  openTabClose: 'Close Tab',
  openTabDesc: 'Allow customers to place multiple orders before payment. Open tab works better with high end restaurants.',
  orderTypes: 'Order Types',
  payments: 'Payments',
  delivery: 'Delivery',
  orders: 'Orders',
  subHeading: 'Do not forget to press save after making some changes.',
  test: 'Test',
  pickup: 'Pickup',
  inStorePickup: 'In Store Pickup',
  fleetHeading: 'Fleet Management',
  noFleetHeading: 'No Fleet Management',
  office: 'Office',
  address: 'Address',
  theatre: 'Theatre',
  other: 'Other',
  driveThru: 'Drive Thru',
  driveThruDesc:
    'Customer will notify you at arrival to pickup the order from their car',
  cash: 'Cash',
  dineIn: 'Dine In',
  onlinePayment: 'Online Payment',
  payAtStore: 'Pay at Store',
  pickupDesc: 'Customer picks up the order',
  deliveryDesc: 'We manage your fleet',
  dineInDesc: 'Table dine in orders',
  minimumDeliveryOrder: 'Minimum Delivery order',
  minimumDeliveryOrderDesc:
    'Customers will need order the minimum before qualifying for delivery',
  deliveryFee: 'Delivery fee',
  deliveryFeeDesc: 'Customers will pay this fee in addition to the order total',
  fleetManagement: 'In house fleet vs. Third party fleet',
  fleetManagementDesc:
    'Are you using your own fleet only or outsourcing delivery to third party. If you don’t know select, default',
  locationTypeTitle: 'Location Type',
  locationTypeDesc:
    'What type of location are you going to deliver to you customer',
  deliveryRadius: 'Delivery Radius',
  deliveryRadiusDesc:
    'Accept delivery orders for customers within this radius only',
  tax: 'Tax',
  taxDesc: 'Is tax included in your menu prices?',
  taxIncludedInPrices: 'Tax is included in prices',
  useSameTimings: 'Apply same Hours for all stores',
  inHouseFleet: 'In house fleet',
  thirdPartyFleet: 'Third party fleet (default)',
  ordersForPaidMembers: 'Orders are enabled for paid members only',
  currency: 'Currency',
  add: 'Add',
  stores: 'Stores',
  menu: 'Menu',
  translations: 'Translations',
  otherLocationDesc: 'Customer can enter location notes',
  theatreDesc: 'Customer enters a seat number',
  officeDesc: 'Customer enters an office number',
  addressDesc: 'Customer enters a location by map',
  enterTax: 'Enter Tax Percentage',
  fleetManagementInfo:
    'Let us manage assignments for your drivers. Your drivers will download an app and receive order notifications automatically',
  noFleetManagementInfo:
    'You manage your own fleet assignments. For each order assignment, we send a message to your driver to notify them of the deliver. No app is needed!',
  km: 'KM',
  mi: 'MI',
};

export const ar: typeof en = {
  openTab: '',
  openTabDesc: '',
  openTabOpen: '',
  openTabClose: '',
  general: 'العام',
  settings: 'الإعدادت',
  orderTypes: 'نوع الطلبات',
  orders: 'طلبات',
  office: 'مكتب',
  theatre: 'صالة سينما أو مسرح',
  other: 'اخر',
  address: 'عنوان على الخريطة',
  stores: 'المتاجر',
  settingsUpdated: 'تم تحديث الأعدادات',
  settingsNotUpdated: 'لايمكن تحديث الأعدادات في الوقت الحالي',
  menu: 'القائمة',
  translations: 'لغات الترجمة',
  locationTypeTitle: 'كيفية تسجيل مقر العميل',
  locationTypeDesc: 'نوع المعلومات اللتي تحتاجها من العميل لتوصيل الطلب',
  payments: 'طريقة الدفع',
  delivery: 'التوصيل',
  subHeading: 'لاتنسى الضغط على حفظ بعد تغير الإعدادات ',
  pickup: 'الاستلام',
  cash: 'كاش',
  dineIn: 'محلي',
  fleetHeading: 'إدارة المناديب',
  noFleetHeading: 'من دون إدارة المناديب',
  onlinePayment: 'مدفوعات الكترونية',
  inStorePickup: 'الاستلام داخل المنشأة',
  driveThru: 'الاستلام من السيارة',
  driveThruDesc:
    'العميل يقوم بتنبيه المنشأه عند الوصول لاستلام الطلب من السيارة',
  payAtStore: 'الدفع في المنشأة',
  pickupDesc: 'العميل يستلم الطلب داخل المنشأة',
  deliveryDesc: 'إدارة المناديب وتتبعهم على الخريطة',
  dineInDesc: 'العميل يطلب طلب محلي في المنشأة',
  minimumDeliveryOrder: 'الحد الأدنى لطلبات التوصيل',
  minimumDeliveryOrderDesc:
    'لابد من العميل شراء محتويات بالحد الأدنى لتقديم خدمة التوصيل',
  deliveryFee: 'رسوم التوصيل',
  deliveryFeeDesc: 'رسوم التوصيل مضافة الى قيمة الطلب',
  fleetManagement: 'مناديب موظفون أو متعاقدين',
  fleetManagementDesc:
    'هل المناديب موظفون في المنشأة (Captive) أو متعاقدين (Contractors)؟',
  deliveryRadius: 'نطاق التوصيل',
  deliveryRadiusDesc: 'استقبل طلبات توصيل في النطاق المحدد',
  tax: 'الضريبة',
  taxDesc: 'هل الضريبة مضافة الى الأسعار؟',
  taxIncludedInPrices: 'الاسعار تشمل الضريبة',
  useSameTimings: 'عمم الأوقات أدناه على جميع المتاجر',
  inHouseFleet: 'مناديب موظفون (Captive)',
  thirdPartyFleet: 'مناديب متعاقدين (Contractors)',
  test: 'تجربة',
  ordersForPaidMembers: 'خدمة الطلبات متوفرة باشتراك شهري',
  currency: 'العملة',
  add: 'اضف',
  otherLocationDesc: 'العميل يقوم بإدخال معلومات الموقع بشكل نصي',
  theatreDesc: 'العميل يقوم بإدخال رقم الكرسي',
  officeDesc: 'العميل يقوم بإدخال رقم المكتب',
  addressDesc: 'العميل يقوم بإدخال الموقع عن طريق الخريطة',
  enterTax: 'ادخل القيمة الضريبية',
  fleetManagementInfo:
    'سنقوم بإدارة مناديبك بشكل تلقائي. سيتم تعين الطلبات لمناديبك بشكل تلقائي عن طريق تطبيق خاص للمناديب ',
  noFleetManagementInfo:
    'انت مسؤول عن إدارة مناديبك. بإمكانك تعيين المندوب لكل طلب وسنقوم بإرسال رساله لتنبيه المندوب بالطلب',
  km: 'كيلو',
  mi: 'ميل',
};
