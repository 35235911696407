import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import * as accountStrings from 'src/account/assets/strings';
import * as commonStrings from 'src/common/assets/strings';
import * as customersStrings from 'src/customers/assets/strings';
import * as deviceStrings from 'src/devices/assets/strings';
import * as forgotPasswordStrings from 'src/forgot-password/assets/strings';
import { formatResources } from 'src/i18n/formatResources';
import { languageDetector } from 'src/i18n/languageDetector';
import * as linksQrStrings from 'src/links-qrcodes/assets/strings';
import * as menuStrings from 'src/menu/assets/strings';
import * as ordersStrings from 'src/orders/assets/strings';
import * as paymentStrings from 'src/payment/assets/strings';
import * as resetStrings from 'src/reset-password/assets/strings';
import * as settingsStrings from 'src/settings/assets/strings';
import * as signupStrings from 'src/signup/assets/strings';
import * as storesStrings from 'src/stores/assets/strings';
import * as translationsStrings from 'src/translations/assets/strings';

const resources = [
  { namespace: 'common', translations: commonStrings },
  { namespace: 'orders', translations: ordersStrings },
  { namespace: 'customers', translations: customersStrings },
  { namespace: 'menu', translations: menuStrings },
  { namespace: 'devices', translations: deviceStrings },
  { namespace: 'stores', translations: storesStrings },
  { namespace: 'signup', translations: signupStrings },
  { namespace: 'links-qr', translations: linksQrStrings },
  { namespace: 'settings', translations: settingsStrings },
  { namespace: 'translations', translations: translationsStrings },
  { namespace: 'payment', translations: paymentStrings },
  { namespace: 'account', translations: accountStrings },
  { namespace: 'forgotPassword', translations: forgotPasswordStrings },
  { namespace: 'resetPassword', translations: resetStrings },
];

export const init = () =>
  i18next
    .use(languageDetector)
    .use(initReactI18next)
    .init({
      fallbackLng: 'en',
      defaultNS: 'common',
      resources: formatResources(resources),
    });
