import { useState } from 'react';
import MXApi from 'src/api/Api';
import { useAuthStore } from 'src/auth/hocs/useAuth';
import { useAlertStore } from 'src/common/hooks/useGlobalAlert';

export const useLogin = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [success, setSuccess] = useState(false);

  const { setAlert } = useAlertStore();

  const { setLoggedIn } = useAuthStore();

  const login = async (username: string, password: string) => {
    try {
      setLoading(true);
      await MXApi.loginUser(username, password);
      setSuccess(true);
      setLoggedIn();
    } catch (e) {
      setError(e.message);
      setAlert('error', e.message);
    } finally {
      setLoading(false);
    }
  };

  return {
    login,
    success,
    loading,
    error,
  };
};
