export const en = {
  user: 'User',
  users: 'Users',
  admin: 'Admin',
  noSearchQuery: 'No Option Found With the Search Query',
  storesSelected: 'Stores Selected',
  addStores: 'Add Stores',
  cancel: 'Cancel',
  january: 'January',
  february: 'February',
  addTranslations: 'Add Translations',
  login: 'Login',
  editTranslations: 'Edit Translations',
  march: 'March',
  april: 'April',
  may: 'May',
  june: 'June',
  july: 'July',
  august: 'August',
  hours: 'Hours',
  september: 'September',
  october: 'October',
  november: 'November',
  december: 'December',
  confirm: 'Confirm',
  name: 'Name',
  about: 'About',
  phoneNumber: 'Phone Number',
  email: 'Email',
  emailAddress: 'Email Address',
  close: 'Close',
  sr: 'SAR',
  update: 'Update',
  remove: 'Remove',
  submit: 'Submit',
  clear: 'Clear',
  open: 'Open',
  closed: 'Closed',
  changeLanguage: 'Change Language',
  delete: 'Delete',
  edit: 'Edit',
  done: 'Done',
  save: 'Save',
  savedSuccess: 'Saved Successfully',
  confirmDelete: 'Are you sure you want to remove this item',
  upgrade: 'Upgrade',
  upgradeMemberShip: 'Upgrade Membership',
  freeMembership: 'Free Membership',
  paidMembership: 'Paid Membership',
  logout: 'Logout',
  empty: 'Nothing yet!',
  logoutConfirmTitle: 'Confirm Logout',
  logoutConfirmMessage: 'You sure you want to logout?',
  yes: 'Yes',
  alert: 'Alert',
  contactSupport: 'Contact Support',
  havingIssues: 'Having issues?',
  comingSoon: 'Coming Soon',
  contactUs: 'Contact Us',
  invalidInput: 'Input is invalid',
  submitError: 'Error in submitting your form',
  deletedSuccessfully: 'Deleted Successfully!',
  updatedSuccessfully: 'Updated Successfully!',
  download: 'Download',
  copied: 'Copied',
  opensAt: 'Opens at',
  closesAt: 'Closes at',
  day: 'Day',
  availability: 'Availability',
  username: 'Username',
  userType: 'User Type',
  password: 'Password',
  disable: 'Disable',
  disabled: 'Disabled',
  enable: 'Enable',
  privacyPolicy: 'Privacy Policy',
  termsOfService: 'Terms of Service',
  thankYou: 'Thank You',
  backToHome: 'Go Home',
  rememberPassword: 'Remember password?',
  emailRequired: 'Please Enter Your Email',
  isDefault: 'Default',
  nothingAddedYet: "It seems like you haven't added anything Yet!",
  allSelected: 'No More Options To Select.',
  previouslySaved: 'Previously Saved',
  addCategory: 'Add Category',
  versionUpdate: 'Version Update',
  versionUpdateDesc:
    'Your current version is outdated. You can update to use the new version now!',
  skip: 'Skip',
  forPaidMembersOnly: 'For Premium Members Only',
};

export const ar: typeof en = {
  users: 'المستخدمين',
  versionUpdate: '',
  versionUpdateDesc: '',
  user: 'المستخدم',
  admin: 'المشرف',
  previouslySaved: 'محفوظه من قبل',
  addCategory: 'اضف قسم',
  allSelected: 'لا يوجد اختيارات اخرى',
  storesSelected: 'المتاجر المختارة',
  noSearchQuery: 'لايوجد بيانات من البحث',
  nothingAddedYet: 'لم يتم اضافة اي بيانات',
  userType: 'نوع المستخدم',
  addStores: 'اضف متجر',
  cancel: 'إلغاء',
  editTranslations: 'تعديل لغات الترجمة',
  rememberPassword: 'تذكر',
  addTranslations: 'اضف لغات ترجمة',
  login: 'تسجيل الدخول',
  confirm: 'تأكيد',
  name: 'الإسم',
  about: 'نبذة',
  hours: 'الساعات',
  upgradeMemberShip: 'ترقية الحساب',
  january: 'يناير',
  february: 'فبراير',
  march: 'مارس',
  april: 'ابريل',
  may: 'مايو',
  june: 'يونيو',
  july: 'يولو',
  august: 'اغسطس',
  september: 'سبتمبر',
  october: 'اكتوبر',
  november: 'نوفمبر',
  december: 'ديسمبر',
  phoneNumber: 'رقم الجوال',
  email: 'إيميل',
  emailAddress: '',
  emailRequired: '',
  close: 'إغلاق',
  sr: 'ريال',
  update: 'تحديث',
  remove: 'حذف',
  submit: 'إرسل',
  clear: 'تفريغ',
  open: 'مفتوح',
  closed: 'مغلق',
  changeLanguage: 'غير اللغة',
  delete: 'حذف',
  edit: 'تعديل',
  done: 'انهاء',
  save: 'حفظ',
  savedSuccess: 'تم الحفظ بنجاح',
  confirmDelete: 'هل انت متأكد تريد الحذف؟',
  upgrade: 'ترقية الحساب',
  freeMembership: 'اشترك مجاني',
  paidMembership: 'اشتراك مدفوع',
  logout: 'سجل الخروج',
  empty: 'لايوجد محتويات',
  logoutConfirmTitle: 'تأكيد تسجيل الخروج',
  logoutConfirmMessage: 'هل تريد تسجيل الخروج؟',
  yes: 'نعم',
  alert: 'تنبيه',
  contactSupport: 'تواصل مع فريق الدعم الفني',
  havingIssues: 'تواجه مشاكل؟',
  comingSoon: 'قادم قريباً',
  contactUs: 'تواصل معنا',
  invalidInput: 'يوجد غلط في المعلومات',
  submitError: 'غلط في عملية التنفيذ',
  deletedSuccessfully: 'تم الحذف بنجاح',
  updatedSuccessfully: 'تم التحديث بنجاح',
  download: 'تحميل',
  copied: 'تم النسخ',
  username: 'اسم المستخدم',
  password: 'كلمة المرور',
  opensAt: 'يفتح الساعة',
  closesAt: 'يغلق الساعة',
  day: 'اليوم',
  availability: 'مغلق أو مفتوح',
  disable: 'ايقاف',
  disabled: 'موقف',
  enable: 'تمكين',
  thankYou: 'شكراً',
  backToHome: 'العودة للصفحة الأساسية',
  privacyPolicy: 'Privacy Policy',
  termsOfService: 'Terms of Use',
  isDefault: 'الأفتراضي',
  skip: 'تخطي',
  forPaidMembersOnly: 'هذه الخاصية للأعضاء فقط',
};
