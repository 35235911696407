import { CssBaseline } from '@material-ui/core';
import { ConfirmProvider } from 'material-ui-confirm';
import React from 'react';
import { ReactQueryDevtools } from 'react-query-devtools';
import { AppRouter } from 'src/router/Router';
import { withTheme } from 'src/theme/hocs/withTheme';

import { Alert } from './common/components/Alert';
import { UpdateVersion } from './update-version/UpdateVersion';

export const App = withTheme(() => (
  <>
    <Alert />
    <UpdateVersion />
    <CssBaseline />
    <ReactQueryDevtools initialIsOpen />
    <ConfirmProvider>
      <AppRouter />
    </ConfirmProvider>
  </>
));
