import {
  Avatar,
  Box,
  createStyles,
  Link,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { Storefront } from '@material-ui/icons';
import React from 'react';
import { useCurrentLanguage } from 'src/i18n/hooks/useCurrentLanguage';
import { t } from 'src/i18n/translate';
import { SupportedLanguage } from 'src/i18n/types';
import { useFetchAccount } from 'src/user/hooks/useFetchAccount';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    large: {
      width: theme.spacing(8),
      height: theme.spacing(8),
    },
  }),
);

export const MemberShipDetails = () => {
  const { merchant, isPaid } = useFetchAccount();
  const { businessName, businessLogo } = merchant || {};
  const { language } = useCurrentLanguage();
  const classes = useStyles();
  return (
    <Box display="flex">
      <Box
        px={5}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center">
        <Avatar variant="rounded" className={classes.large} src={businessLogo}>
          <Storefront />
        </Avatar>
        <Box
          display="flex"
          pt={2}
          flexDirection="column"
          alignItems="center"
          justifyContent="center">
          <Typography variant="h6" style={{ fontWeight: 'bold' }}>
            {language === SupportedLanguage.Arabic
              ? businessName?.ar
              : businessName?.en}
          </Typography>
          <Typography variant="subtitle1">
            {isPaid ? t('common:paidMembership') : t('common:freeMembership')}
            {!isPaid && (
              <Link
                href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}?subject=Upgrade Membership`}
                underline="always"
                className="link"
                color="secondary">
                {t('common:upgrade')}
              </Link>
            )}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
