export const en = {
  translations: 'Translations',
  languageDialogHeadline:
    'What languages do you want your menu and store info to be in?',
  addTranslation: 'Add Language',
  chooseLanguage: 'Choose a language',
  primary: 'Primary',
  makePrimary: 'Make Primary',
  menuItemCategoryMessage: 'Please Select Menu Item Category',
  menuItemPriceMessage: 'Please Enter Menu Item Price',
  deleteTranslationMessage:
    'By deleting this language you will remove the language translations from all stores and menus.',
};

export const ar: typeof en = {
  translations: 'الترجمة',
  menuItemPriceMessage: 'الرجاء ادخال السعر',
  menuItemCategoryMessage: 'الرجاء اختيار القسم',
  languageDialogHeadline:
    'ما هي اللغات التي تريد أن تكون معلومات القائمة والمتجر بها؟',
  addTranslation: 'اضف لغة ترجمة',
  chooseLanguage: 'اختر اللغة',
  primary: 'اللغة الأساسية',
  makePrimary: 'اختيار كاللغة أساسية',
  deleteTranslationMessage: 'احذف اللغة',
};
