import { Languages } from 'src/translations/consts';
import { Language } from 'src/translations/uiTypes';

export const isRTL = (lang: string) =>
  lang === 'ar' ||
  lang === 'he' ||
  lang === 'ur' ||
  lang === 'dv' ||
  lang === 'ku' ||
  lang === 'fa';

export const convertApiLanguageToUILanguage = (language: string): Language =>
  Languages.find(lang => language === lang.code) ?? Languages[0];

export const convertApiLanguagesToUILanguages = (
  languages: string[],
): Language[] =>
  languages.map(item => convertApiLanguageToUILanguage(item)).flat();
