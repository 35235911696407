import {
  IconButton,
  makeStyles,
  SnackbarContent,
  Theme,
} from '@material-ui/core';
import { amber, green } from '@material-ui/core/colors';
import {
  CheckCircle as CheckCircleIcon,
  Close as CloseIcon,
  Error as ErrorIcon,
  Info as InfoIcon,
  Warning as WarningIcon,
} from '@material-ui/icons';
import clsx from 'clsx';
import React, { memo } from 'react';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    flex: 1,
  },
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  messageWrapper: {
    flex: 1,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export interface Props {
  className?: string;
  message?: string;
  onClose?: (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string | undefined,
  ) => void;
  variant?: 'success' | 'error' | 'warning' | 'info';
  hideClose?: boolean;
}

export const CustomSnackbarContent = memo<Props>(
  ({
    className,
    message,
    onClose,
    hideClose,
    variant = 'info',
    ...restProps
  }) => {
    const classes = useStyles();
    const Icon = variantIcon[variant];

    return (
      <SnackbarContent
        className={clsx(classes[variant], className)}
        classes={{
          message: classes.messageWrapper,
        }}
        message={
          <span className={classes.message}>
            <Icon className={clsx(classes.icon, classes.iconVariant)} />
            {message}
          </span>
        }
        action={
          !hideClose && (
            <IconButton color="inherit" onClick={onClose}>
              <CloseIcon className={classes.icon} />
            </IconButton>
          )
        }
        {...restProps} // for animations
      />
    );
  },
);
