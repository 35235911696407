import {
  createStyles,
  Drawer,
  Hidden,
  makeStyles,
  Theme,
  Toolbar,
} from '@material-ui/core';
import {
  Group,
  Link,
  PhonelinkRing,
  Receipt,
  RestaurantMenu,
  Settings,
  Store,
} from '@material-ui/icons';
import React, { memo } from 'react';
import { useAuthStore } from 'src/auth/hocs/useAuth';
import { t } from 'src/i18n/translate';
import { MenuLinks } from 'src/sidemenu/components/MenuLinks';
import { LinkType, SideMenuLink } from 'src/sidemenu/hooks/useSideMenu';

const drawerWidth = 250;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
  }),
);

interface Props {
  desktopOpen: boolean;
  mobileOpen: boolean;
  onMobileClose: () => void;
}

export const SideMenu = memo<Props>(
  ({ desktopOpen, mobileOpen, onMobileClose }) => {
    const sideMenuLinks: SideMenuLink[] = [
      {
        key: LinkType.Stores,
        label: t('stores:stores'),
        to: '/',
        icon: <Store />,
      },
      {
        key: LinkType.Menu,
        label: t('menu:menu'),
        to: '/menu',
        icon: <RestaurantMenu />,
      },
      {
        key: LinkType.Devices,
        label: t('devices:posLogins'),
        icon: <PhonelinkRing />,
        to: '/devices',
      },
      {
        key: LinkType.Orders,
        label: t('orders:orders'),
        icon: <Receipt />,
        to: '/orders',
      },
      {
        key: LinkType.QR_Link,
        label: t('links-qr:links'),
        icon: <Link />,
        to: '/links',
      },
      {
        key: LinkType.Customers,
        label: t('customers:customers'),
        icon: <Group />,
        to: '/customers',
      },
      {
        key: LinkType.OrderSettings,
        label: t('settings:settings'),
        to: '/settings/orders',
        icon: <Settings />,
        subLinks: [
          {
            to: '/settings/orders',
            label: t('settings:orders'),
            key: LinkType.OrderSettings,
          },
          {
            to: '/settings/payment',
            label: t('settings:payments'),
            key: LinkType.Payments,
          },
          {
            to: '/settings/stores',
            label: t('settings:stores'),
            key: LinkType.StoreSettings,
          },
          {
            to: '/settings/menu',
            label: t('settings:menu'),
            key: LinkType.MenuSettings,
          },
          {
            to: '/settings/translations',
            label: t('settings:translations'),
            key: LinkType.Translations,
          },
        ],
      },
    ];

    const { isLoggedIn } = useAuthStore();

    const classes = useStyles();

    const drawerClasses = {
      paper: classes.drawerPaper,
    };

    if (!isLoggedIn) {
      return null;
    }

    return (
      <nav>
        <Hidden smDown>
          <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={desktopOpen}
            classes={{
              paper: classes.drawerPaper,
            }}>
            <Toolbar />
            <MenuLinks links={sideMenuLinks} />
          </Drawer>
        </Hidden>
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            classes={drawerClasses}
            open={mobileOpen}
            onClose={onMobileClose}
            elevation={1}>
            <MenuLinks links={sideMenuLinks} />
          </Drawer>
        </Hidden>
      </nav>
    );
  },
);
