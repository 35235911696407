import { SupportedLanguage } from 'src/i18n/types';
import { Label, Store } from 'src/stores/apiTypes';
import { Merchant } from 'src/translations/apiTypes';

export interface Account {
  id?: string;
  name: string;
  settingsId?: string;
  merchants?: Merchant[];
  membership?: Membership;
  users?: User[];
}

export interface Version {
  minimumVersion: string;
  os: string;
  forceUpdate: boolean;
}

interface OneTimeCode {
  code: string;
}

export interface User {
  id: string;
  fullName: Opt<string>;
  admin?: boolean;
  firstName: string;
  lastName?: Opt<string>;
  userName: string;
  name: string;
  about?: Opt<string>;
  emailAddress?: Opt<string>;
  phoneNumber: string;
  roles?: Opt<string[]>;
  timezone?: Opt<string>;
  location?: Opt<Location>;
  profilePic?: Opt<string>;
  gender?: Opt<string>;
  birthDate?: Opt<string>;
  lastActiveDate?: Opt<string>;
  oneTimeLoginCode: OneTimeCode;
  isHidden?: boolean;
  password: string;
  stores?: Opt<Store[]>;
  region?: Opt<string>;
  language?: Opt<SupportedLanguage>;
  eventsDistinctId?: Opt<string>;
  isActive: boolean;
  businessLogo: Opt<string>;
  businessName: Opt<Label>;
}

export interface Languages {
  supported: Languages[];
  primary: SupportedLanguage;
}

export enum MembershipRenewType {
  Monthly = 'MONTHLY',
  Yearly = 'YEARLY',
}

export enum MembershipType {
  Free = 'FREE',
  Paid = 'PAID',
}

export interface Membership {
  userId: string;
  renewType: MembershipRenewType;
  type: MembershipType;
  startDate: string;
  isActive: boolean;
}

export interface UserRegistration {
  user: User;
  password: string;
  confirmPassword: string;
  isCourier: boolean;
}

export interface Token {
  access_token: string;
  token_type: string;
  refresh_token: string;
  expires_in: number;
}
