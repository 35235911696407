import { Snackbar } from '@material-ui/core';
import React, { memo } from 'react';
import { useAlertStore } from 'src/common/hooks/useGlobalAlert';

import { CustomSnackbarContent } from './CustomSnackbarContent';

export const Alert = memo(() => {
  const {
    alertIsOpen,
    alertType,
    alertMessage,
    closeAlert,
    hideClose,
    anchorOrigin,
  } = useAlertStore();
  const handleClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    // https://stackoverflow.com/questions/56913963/cannot-invoke-an-object-which-is-possibly-undefined-ts2722
    closeAlert && closeAlert();
  };
  return (
    <Snackbar
      anchorOrigin={anchorOrigin}
      open={alertIsOpen}
      autoHideDuration={4000}
      onClose={handleClose}>
      <CustomSnackbarContent
        onClose={handleClose}
        variant={alertType}
        message={alertMessage}
        hideClose={hideClose}
      />
    </Snackbar>
  );
});
