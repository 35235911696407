export const en = {
  account: 'Account',
  overview: 'Overview',
  accountDetails: 'Account Details',
  accountName: 'Account Name',
  dateJoined: 'Date Joined',
  merchantDetails: 'Merchant Details',
  usage: 'Usage',
  totalOrders: 'Total Orders',
  totalMessages: 'Total Messages',
  users: 'Users',
  username: 'Username',
  userType: 'User Type',
  memberShipType: 'Membership Type',
};

export const ar: typeof en = {
  account: 'الحساب',
  accountDetails: 'معلومات الحساب',
  overview: 'نبذة',
  accountName: 'اسم الحساب',
  dateJoined: 'تاريخ الأشتراك',
  merchantDetails: 'تفاصيل مالك المنشأة',
  usage: 'بيانات الاستخدام',
  totalOrders: 'مجموع الطلبات',
  totalMessages: 'مجموع الرسائل',
  users: 'المستخدمين',
  username: 'اسم المستخدم',
  userType: 'نوع المستخدم',
  memberShipType: 'نوع الإشتراك',
};
