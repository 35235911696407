import { LanguageDetectorModule } from 'i18next';
import { getPreferredLanguage } from 'src/i18n/getPreferredLanguage';

export const languageDetector: LanguageDetectorModule = {
  type: 'languageDetector',
  init: () => {},
  cacheUserLanguage: () => {
    /*
     * TODO: Cache language
     */
  },
  detect: getPreferredLanguage,
};
