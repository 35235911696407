import {
  Box,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { ControlPoint } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { useConfirm } from 'material-ui-confirm';
import React, { memo, useEffect, useState } from 'react';
import { Loader } from 'src/common/components/Loader';
import { t } from 'src/i18n/translate';
import { Merchant } from 'src/translations/apiTypes';
import { LanguageRow } from 'src/translations/components/TranslationsDialog/LanguageRow';
import { Languages } from 'src/translations/consts';
import { useTranslationsStore } from 'src/translations/hooks/useTranslations';
import { useUpdateLanguages } from 'src/translations/hooks/useUpdateLanguages';
import { Language } from 'src/translations/uiTypes';
import { convertApiLanguagesToUILanguages } from 'src/translations/utils/languageUtils';
import {
  addLanguage,
  makePrimaryLanguage,
  removeLanguage,
  toggleDisabledLanguage,
} from 'src/translations/utils/merchantActions';
import { useFetchAccount } from 'src/user/hooks/useFetchAccount';

interface Props {
  showCancel?: boolean;
}

const TranslationsForm = memo<Props>(({ showCancel = false }) => {
  const { merchant, loading: loadingMerchant } = useFetchAccount();
  const { toggleDialog, closeDialog, setUpdated } = useTranslationsStore();
  const [merchantObj, setMerchantObj] = useState<Merchant>();
  const [isAddingLanguage, setIsAddingLanguage] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [selectedLang, setSelectedLang] = useState<Language | null>();
  const { updateLanguages, loading } = useUpdateLanguages();

  const supportedLanguages = convertApiLanguagesToUILanguages(
    merchant?.languages.supported ?? [],
  );
  const disabledLanguages = convertApiLanguagesToUILanguages(
    merchant?.languages.disabled ?? [],
  );

  useEffect(() => {
    if (!loadingMerchant && merchant) {
      setMerchantObj(merchant);
    }
    return () => setIsSaveDisabled(true);
  }, [loadingMerchant, merchant]);

  const confirm = useConfirm();

  const handleClose = () => {
    toggleDialog();
    setIsAddingLanguage(false);
  };

  const handleSaveMerchant = () => {
    merchantObj && updateLanguages(merchantObj.id, merchantObj.languages);
    closeDialog();
    setUpdated();
  };

  const handleRemoveLang = async (lang: Language) => {
    try {
      await confirm({
        title: `${t('common:confirmDelete')} ${lang?.name}`,
        description: `
             ${t('translations:deleteTranslationMessage')}`,
        confirmationText: t('common:yes'),
        cancellationText: t('common:cancel'),
        confirmationButtonProps: {
          color: 'secondary',
        },
      });
      merchantObj && setMerchantObj({ ...removeLanguage(merchantObj, lang) });
    } catch (e) {}
    setIsSaveDisabled(false);
  };

  const handleMakePrimary = (lang: Language) => {
    merchantObj &&
      setMerchantObj({ ...makePrimaryLanguage(merchantObj, lang) });
    setIsSaveDisabled(false);
  };

  const handleToggleDisableLang = (lang: Language) => {
    merchantObj &&
      setMerchantObj({ ...toggleDisabledLanguage(merchantObj, lang) });
    setIsSaveDisabled(false);
  };

  if (loadingMerchant) {
    return (
      <Box
        display="flex"
        height={1}
        width={1}
        justifyContent="center"
        alignItems="center">
        <Loader />
      </Box>
    );
  }
  return (
    <>
      <Grid
        direction="column"
        spacing={2}
        style={{
          height: '100%',
          width: '100%',
        }}>
        {merchantObj &&
          supportedLanguages.map(language => (
            <LanguageRow
              makeLanguagePrimary={() => handleMakePrimary(language)}
              handleToggleDisableLang={() => handleToggleDisableLang(language)}
              handleDelete={() => handleRemoveLang(language)}
              key={language?.code}
              primary={merchantObj.languages.primary === language?.code}
              disabled={!!disabledLanguages.find(lang => language === lang)}
              lang={language}
            />
          ))}
        {isAddingLanguage && (
          <Grid item xs={12}>
            <Autocomplete
              options={Languages as Language[]}
              value={selectedLang}
              onChange={(
                event: React.ChangeEvent<{}>,
                newValue: Language | null,
              ) => {
                setSelectedLang(newValue);
                if (newValue && merchantObj) {
                  setMerchantObj(addLanguage(merchantObj, newValue));
                }
                setIsAddingLanguage(false);
                setIsSaveDisabled(false);
              }}
              autoHighlight
              getOptionLabel={option => option.name}
              renderOption={option => (
                <React.Fragment>
                  <Typography> {option.name}</Typography>
                </React.Fragment>
              )}
              renderInput={params => (
                <TextField
                  {...params}
                  label={t('translations:chooseLanguage')}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password', // disable autocomplete and autofill
                  }}
                />
              )}
            />
          </Grid>
        )}
      </Grid>
      <Grid className="p-2" item xs={12}>
        <Button
          onClick={() => setIsAddingLanguage(!isAddingLanguage)}
          size="large"
          startIcon={<ControlPoint color="secondary" />}
          color="secondary">
          {t('translations:addTranslation')}
        </Button>
      </Grid>
      <Grid
        className="p-1"
        item
        container
        justify={showCancel ? 'center' : 'flex-start'}>
        {showCancel && (
          <Grid item>
            <Box marginX={1}>
              <Button variant="outlined" size="large" onClick={handleClose}>
                {t('common:cancel')}
              </Button>
            </Box>
          </Grid>
        )}

        <Grid item alignContent="flex-start">
          <Box my={showCancel ? 0 : 3} marginX={1}>
            <Button
              disableElevation
              disabled={isSaveDisabled}
              onClick={handleSaveMerchant}
              style={{
                fontWeight: 600,
                color: '#fff',
                padding: '7px 40px',
              }}
              variant="contained"
              color="secondary">
              {loading && (
                <CircularProgress className="mr-2" color="inherit" size={18} />
              )}
              {t('common:save')}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
});

export default TranslationsForm;
