import MXCookies from 'src/utils/MXCookies';

export const useLogout = () => {
  const logout = () => {
    MXCookies.reset();
    window.open('/', '_self');
  };
  return {
    logout,
  };
};
