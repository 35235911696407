import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import React, { useState } from 'react';
import { TopBar } from 'src/common/components/Page/TopBar';
import { SideMenu } from 'src/sidemenu/components/SideMenu';

export const Navigation = () => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [desktopOpen, setDesktopOpen] = useState(true);
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    if (isMobile) {
      setMobileOpen(!mobileOpen);
    } else {
      setDesktopOpen(!desktopOpen);
    }
  };
  return (
    <div>
      <TopBar onMenuClick={handleDrawerToggle} />
      <SideMenu
        mobileOpen={mobileOpen}
        desktopOpen={desktopOpen}
        onMobileClose={handleDrawerToggle}
      />
    </div>
  );
};
