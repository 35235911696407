import { createMuiTheme, responsiveFontSizes } from '@material-ui/core';
import { lightBlue } from '@material-ui/core/colors';
import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';

import { BG_COLOR, ERROR_COLOR, SUCCESS_COLOR } from './consts';

export const createTheme = (options: ThemeOptions = {}) => {
  return responsiveFontSizes(
    createMuiTheme({
      overrides: {
        MuiPickersToolbar: {
          toolbar: {
            backgroundColor: SUCCESS_COLOR,
          },
        },
        MuiPickersCalendarHeader: {
          switchHeader: {
            backgroundColor: lightBlue.A200,
            color: 'white',
          },
        },
        MuiPickersDay: {
          day: {
            color: lightBlue.A700,
          },
          daySelected: {
            backgroundColor: lightBlue['400'],
          },
          dayDisabled: {
            color: lightBlue['100'],
          },
          current: {
            color: SUCCESS_COLOR,
          },
        },
        MuiPickersModal: {
          dialogAction: {
            color: lightBlue['400'],
          },
        },
      },
      props: {
        // Name of the component
        MuiButtonBase: {
          // The properties to apply
          disableRipple: true, // No more ripple, on the whole application!
        },
      },
      palette: {
        primary: {
          main: 'rgba(28,28,30,0.93)',
          light: 'rgb(99,99,99)',
        },
        secondary: {
          main: '#0BD19F',
          light: 'rgb(99,99,99)',
        },
        background: {
          default: BG_COLOR,
        },
        success: { main: SUCCESS_COLOR },
        error: { main: ERROR_COLOR },
      },
      typography: {
        fontSize: 13,
        fontFamily: [
          'Poppins',
          'DroidNaskh-Regular',
          'Roboto',
          '"Helvetica Neue"',
          'Arial',
          'sans-serif',
        ].join(','),
      },
      ...options,
    }),
  );
};
