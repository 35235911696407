import { Token } from 'src/user/apiTypes';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export default class MXCookies {
  public static getAccessToken(): Opt<Token> {
    const tokenString = localStorage.getItem('token');
    if (tokenString) {
      return JSON.parse(tokenString);
    }
    return undefined;
  }

  /**
   * Set the access token and save it to storage.
   *
   * @param token The access token to be saved
   * @returns {*}
   */
  public static async setAccessToken(token: Token) {
    return localStorage.setItem('token', JSON.stringify(token));
  }

  /**
   * Set the access token expiration date.
   *
   * @param token The expiration date for the token.
   * @returns {*}
   */
  public static setTokenExpirationDate(date: Opt<Date>) {
    if (!date) {
      return;
    }
    const dateString = date.toISOString();
    cookies.set('token_expiration', dateString);
  }

  /**
   *  Get the access token expiration date.
   *
   * @returns {*}
   */
  public static async getTokenExpirationDate(): Promise<Opt<Date>> {
    const dateString = await cookies.get('token_expiration');
    if (dateString == null) {
      return undefined;
    }
    return new Date(dateString);
  }

  public static reset() {
    localStorage.clear();
    cookies.remove('token');
    cookies.remove('token_expiration');
  }
}
