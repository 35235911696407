import Api from 'src/api/Api';
import create from 'zustand';

interface AuthProps {
  isLoggedIn: boolean;
  setLoggedIn: () => void;
}

export const [useAuthStore] = create<AuthProps>(set => ({
  isLoggedIn: Api.isUserLoggedIn(),
  setLoggedIn: () => set({ isLoggedIn: true }),
}));
