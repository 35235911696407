import { Box, Collapse, List, Typography } from '@material-ui/core';
import React, { memo, useCallback, useEffect } from 'react';
import { MenuLink } from 'src/sidemenu/components/MenuLink';
import {
  LinkType,
  SideMenuLink,
  useSideMenu,
} from 'src/sidemenu/hooks/useSideMenu';

interface Props {
  links: SideMenuLink[];
}

export const MenuLinks = memo<Props>(({ links }) => {
  const {
    expandedSections,
    setExpandedSections,
    setSelectedItem,
    selectedItem,
  } = useSideMenu();

  const location = window.location;

  const onSectionExpand = (sectionType: LinkType) => {
    let section = expandedSections.find(({ type }) => type === sectionType);
    if (!section) {
      section = {
        type: sectionType,
        isExpanded: false,
      };
    }
    const { isExpanded } = section;
    section.isExpanded = !isExpanded;
    setExpandedSections([section]);
  };

  const onSectionExpandCallback = useCallback(onSectionExpand, [LinkType]);

  useEffect(() => {
    links.forEach(link => {
      if (link.to === location.pathname) {
        setSelectedItem(link.key);
        //onSectionExpandCallback(link.key);
      }
      link.subLinks?.forEach(subLink => {
        if (subLink.to === location.pathname) {
          setSelectedItem(subLink.key);
          //onSectionExpandCallback(link.key);
        }
      });
    });
  }, [links, onSectionExpandCallback, selectedItem, setSelectedItem, location]);

  const isSectionExpanded = (sectionType: LinkType) => {
    const section = expandedSections.find(({ type }) => type === sectionType);
    return section?.isExpanded;
  };

  const handleMenuClick = (link: SideMenuLink) => {
    setSelectedItem(link.key);
    onSectionExpandCallback(link.key);
  };

  const renderLinks = () =>
    links.map(link => {
      const linkExpanded = isSectionExpanded(link.key);
      return (
        <div key={link.key}>
          <MenuLink
            icon={link.icon}
            text={link.label}
            to={link.to}
            collapsable={link.subLinks && link.subLinks.length > 0}
            onClick={() => {
              handleMenuClick(link);
            }}
            isExpanded={linkExpanded}
          />
          {link.subLinks && (
            <Collapse in={linkExpanded} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {link.subLinks.map(subLink => (
                  <MenuLink
                    key={subLink.key}
                    text={subLink.label}
                    isSubLink={true}
                    to={subLink.to}
                    disableGutters
                    onClick={() => {
                      setSelectedItem(subLink.key);
                    }}
                  />
                ))}
              </List>
            </Collapse>
          )}
        </div>
      );
    });

  return (
    <>
      <List>
        {renderLinks()}
        <Box display="flex" pt={4}>
          <Box m={3}>
            <Typography color="textSecondary" variant="body2">
              {`v${process.env.REACT_APP_VERSION}`}
            </Typography>
          </Box>
        </Box>
      </List>
    </>
  );
});
