import {
  jssPreset,
  StylesProvider,
  Theme,
  ThemeProvider as Provider,
} from '@material-ui/core/styles';
import { create } from 'jss';
import rtl from 'jss-rtl';
import React from 'react';

import { createTheme } from './theme';

interface Props {
  children: React.ReactChild;
  theme?: Theme;
}

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

export const RtlThemeProvider = ({
  theme = createTheme({ direction: 'rtl' }),
  children,
}: Props) => (
  <Provider theme={theme}>
    <StylesProvider jss={jss}>{children}</StylesProvider>
  </Provider>
);
