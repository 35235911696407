export const ROUNDNESS =50;
export const CARD_BG_COLOR = 'rgb(255,255,255)';
export const BG_COLOR = 'rgb(246,246,248)';
export const SUCCESS_COLOR = 'rgb(29,198,152)';
export const SUCCESS_COLOR_DARKER = 'rgb(27,174,134)';
export const PRIMARY_COLOR = '#68d2a0';
export const ERROR_COLOR = 'rgb(219,23,23)';
export const SECONDARY_COLOR = SUCCESS_COLOR;
export const OPAQUE_PRIMARY_COLOR = 'rgba(29,214,164, 0.2)';
export const BLUE_COLOR = 'rgb(64,190,246)';
export const YELLOW_COLOR = 'rgb(221,182,59)';
export const GREEN_COLOR = 'rgb(28,205,135)';
export const BLACK_COLOR = '#333';
export const DISABLED_COLOR = '#00000042';
