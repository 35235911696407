import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Router } from '@reach/router';
import React, { lazy, memo } from 'react';
import { useAuthStore } from 'src/auth/hocs/useAuth';
import { ContentWrapper } from 'src/common/components/Wrapper/ContentWrapper';
import { Navigation } from 'src/navigation/Navigation';
import { RoutePage } from 'src/router/components/RoutePage/RoutePage';

const AddCategoryPage = lazy(
  () =>
    import(
      'src/menu/menu-category/components/add-category-page/AddCategoryPage'
    ),
);
const PaymentPage = lazy(
  () => import('src/payment/components/PaymentPage/PaymentPage'),
);

export const MenuItemsPageContainer = lazy(
  () => import('src/menu/menu-item/containers/MenuItemsPageContainer'),
);
const StoresPage = lazy(
  () => import('src/stores/components/StoresPage/StoresPage'),
);
const AddStorePage = lazy(
  () => import('src/stores/components/AddStorePage/AddStorePage'),
);
const EditStorePage = lazy(
  () => import('src/stores/components/EditStorePage/EditStorePage'),
);
const DevicesPageContainer = lazy(
  () => import('src/devices/containers/DevicesPageContainer'),
);
const AddDevicePage = lazy(
  () => import('src/devices/components/AddDevicePage/AddDevicePage'),
);
const EditDevicePage = lazy(
  () => import('src/devices/components/EditDevicePage/EditDevicePage'),
);

const EditCategoryPage = lazy(
  () =>
    import(
      'src/menu/menu-category/components/edit-category-page/EditCategoryPage'
    ),
);
const ThankYouPaymentPage = lazy(
  () =>
    import('src/payment/components/ThankYouPaymentPage/ThankYouPaymentPage'),
);
const SignupPage = lazy(() => import('src/signup/components/SignupPage'));

const OrdersPageContainer = lazy(
  () => import('src/orders/components/OrdersPage/OrdersPage'),
);

const LinksPageContainer = lazy(
  () => import('src/links-qrcodes/containers/LinksPageContainer'),
);

const QRCoedsPageContainer = lazy(
  () => import('src/links-qrcodes/containers/QRCodesPageContainer'),
);

const OrderSettings = lazy(
  () => import('src/settings/components/SettingsPage/OrdersSettings'),
);

const AccountPage = lazy(() => import('src/account/components/AccountDetails'));

const CustomersPage = lazy(
  () => import('src/customers/components/CustomersPage/CustomersPage'),
);

const CustomerPage = lazy(
  () => import('src/customers/components/CustomerPage/CustomerPage'),
);

const MenuSettings = lazy(
  () => import('src/settings/components/SettingsPage/MenuSettings'),
);

const TranslationSettingsPage = lazy(
  () => import('src/settings/components/SettingsPage/TranslationSettingsPage'),
);

const PaymentsPage = lazy(
  () => import('src/settings/components/SettingsPage/PaymentSettings'),
);

const StoreSettingsPage = lazy(
  () => import('src/settings/components/SettingsPage/StoreSettings'),
);

const ForgotPasswordPage = lazy(
  () => import('src/forgot-password/ForgotPasswordPage'),
);

const ResetPasswordPage = lazy(
  () => import('src/reset-password/ResetPasswordPage'),
);

const AddMenuItemPage = lazy(
  () =>
    import('src/menu/menu-item/components/add-menuitem-page/AddMenuItemPage'),
);

const EditMenuItemPage = lazy(
  () =>
    import('src/menu/menu-item/components/edit-menuitem-page/EditMenuItemPage'),
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flex: 1,
      padding: 10,
    },
    toolbar: theme.mixins.toolbar,
  }),
);

interface Props {
  title: string;
  children: React.ReactNode;
}

export const AppRouter = memo(() => {
  const classes = useStyles();

  const { isLoggedIn } = useAuthStore();

  return (
    <div className="fill">
      <div className={`${isLoggedIn ? classes.root : null} `}>
        <Navigation />
        <ContentWrapper>
          <Router>
            <RoutePage path="/" Component={StoresPage} />
            <RoutePage path="/add-item" Component={AddMenuItemPage} />
            <RoutePage path="/edit-item" Component={EditMenuItemPage} />
            <RoutePage path="/edit-category" Component={EditCategoryPage} />

            <RoutePage path="/add-category" Component={AddCategoryPage} />
            <RoutePage path="/menu" Component={MenuItemsPageContainer} />
            <RoutePage path="/add-store" Component={AddStorePage} />
            <RoutePage path="/edit-store/:storeId" Component={EditStorePage} />
            <RoutePage path="/devices" Component={DevicesPageContainer} />
            <RoutePage path="/add-device" Component={AddDevicePage} />
            <RoutePage
              path="/edit-device/:operatorId"
              Component={EditDevicePage}
            />
            <RoutePage path="/orders" Component={OrdersPageContainer} />
            <RoutePage path="/qr-codes" Component={QRCoedsPageContainer} />
            <RoutePage path="/links" Component={LinksPageContainer} />
            <RoutePage path="/settings/orders" Component={OrderSettings} />
            <RoutePage path="/settings/payment" Component={PaymentsPage} />
            <RoutePage path="/settings/stores" Component={StoreSettingsPage} />
            <RoutePage path="/settings/menu" Component={MenuSettings} />

            <RoutePage
              path="/settings/translations"
              Component={TranslationSettingsPage}
            />
            <RoutePage path="/account" Component={AccountPage} />
            <RoutePage path="/payment" Component={PaymentPage} withoutAuth />
            <RoutePage path="/customers" Component={CustomersPage} />
            <RoutePage path="/customer/:customerId" Component={CustomerPage} />
            <RoutePage
              path="/payment-success"
              Component={ThankYouPaymentPage}
              withoutAuth
            />
            <RoutePage
              path="/signup/:token"
              Component={SignupPage}
              withoutAuth
            />
            <RoutePage
              withoutAuth
              path="/forgot-password"
              Component={ForgotPasswordPage}
            />
            <RoutePage
              path="/reset-password"
              withoutAuth
              Component={ResetPasswordPage}
            />
          </Router>
        </ContentWrapper>
      </div>
    </div>
  );
});
