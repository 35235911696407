import {
  Box,
  Button,
  Dialog,
  DialogActions,
  Typography,
} from '@material-ui/core';
import React from 'react';
import {
  NewDialogTitle,
  useAlertDialog,
} from 'src/common/components/CustomDialog';
import { useFetchVersion } from 'src/common/hooks/useUpdateVersion';
import { t } from 'src/i18n/translate';

export const UpdateVersion = () => {
  const { open, handleClose, handleClickOpen } = useAlertDialog();

  const { loading, data } = useFetchVersion();

  React.useEffect(() => {
    if (!loading && data?.forceUpdate) {
      handleClickOpen();
    }
  }, [data, handleClickOpen, loading]);

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      PaperProps={{
        style: {
          padding: 10,
          maxWidth: 630,
        },
      }}
      fullWidth
      maxWidth="sm">
      <NewDialogTitle id="customized-dialog-title" onClose={handleClose}>
        <Box display="flex" flexDirection="column">
          <Typography variant="h5" className="font-weight-800 mb-2">
            {t('common:versionUpdate')}
          </Typography>
          <span className="order-muted">{t('common:versionUpdateDesc')}</span>
        </Box>
      </NewDialogTitle>
      <DialogActions
        style={{
          justifyContent: 'flex-start',
          padding: '10px 24px',
        }}>
        <Button
          style={{
            padding: '7px',
          }}
          variant="outlined"
          onClick={handleClose}>
          {t('common:cancel')}
        </Button>
        <Button
          disableElevation
          style={{
            fontWeight: 900,
            color: '#fff',
            padding: '8px 50px',
          }}
          onClick={() => window.location.reload(true)}
          color="secondary"
          variant="contained">
          {t('common:update')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
