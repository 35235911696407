import { RouteComponentProps } from '@reach/router';
import React, {
  ComponentType,
  LazyExoticComponent,
  memo,
  Suspense,
} from 'react';
import { withAuth as withAuthHoc } from 'src/auth/hocs/withAuth';

interface Props extends RouteComponentProps {
  Component: LazyExoticComponent<ComponentType<any>>;
  withoutAuth?: boolean;
}

const withSuspense = Component => () => (
  <Suspense fallback={<></>}>{<Component />}</Suspense>
);

export const RoutePage = memo<Props>(
  ({ Component, withoutAuth, ...restProps }) => {
    let WrappedComp;
    if (withoutAuth) {
      WrappedComp = withSuspense(Component) as any;
    } else {
      WrappedComp = withSuspense(withAuthHoc(Component)) as any;
    }

    return <WrappedComp {...restProps} />;
  },
);
