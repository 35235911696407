export const en = {
  passwordHeading: 'Forgot Password',
  sendResetLink: 'Send Reset Link',
  forgotPasswordSuccessHeading: 'We sent you the instructions',
  forgotPasswordSuccessSubHeading:
    'Check your inbox to see how to reset your password',
  helperText:
    'Enter your email address below and we will send you a link to reset your password.',
};

export const ar: typeof en = {
  passwordHeading: 'نسيت كلمة المرور',
  helperText: 'ارسل رابط لإسترجاع كلمة المرور',
  sendResetLink: 'ارسل الرابط',
  forgotPasswordSuccessHeading: 'تم ارسال الرابط بنجاح',
  forgotPasswordSuccessSubHeading: 'ادخل الايميل ادناه',
};
