export const en = {
  qr_codes: 'QR Codes',
  links: 'Links',
  linksQr: 'Links & QR codes',
  viewMenuMessage: 'View your digital menu',
  contactUsQrCode: 'Contact us to get your QR Code',
  linksSubtitle: 'View your stores and digital menu via links',
  downloadQR: 'QR code',
  viewMenu: 'Visit Site',
  QRCodeFor: 'GET QR CODE',
  copyLink: 'Copy Link',
  noLinksPlaceHolder:
    'Create your first store and your link will be created automatically',
  noLinks: 'No Links',
};

export const ar: typeof en = {
  qr_codes: 'كيو ار كود',
  links: 'روابط',
  linksQr: 'روابط و كيواركود',
  viewMenuMessage: 'اعرض المنيو الالكتروني لمنشأتك',
  contactUsQrCode: 'تواصل معنا لحصول على الكود لمنشأتك',
  linksSubtitle: 'تصفح متجرك والمنيو الالكتروني عبر الروابط',
  downloadQR: 'كيو ار كود',
  viewMenu: 'تصفح الموقع',
  QRCodeFor: 'احصل على الكيو ار كود',
  copyLink: 'انسخ',
  noLinksPlaceHolder: 'قم بإنشأ متجرك والرابط سيظهر هنا',
  noLinks: 'لايوجد روابط',
};
