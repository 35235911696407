export const en = {
  resetPasswordSuccessHeading: 'Password Updated Successfully!',
  resetPasswordSubHeading:
    'You can navigate back to the main screen to login to your account',
  goBackToHome: 'Go Back To Home.',
  resetPassword: 'Reset Password',
  resetPasswordRequired: 'You must specify a password',
  passwordNotMatch: 'The passwords do not match'
};

export const ar: typeof en = {
  resetPasswordSuccessHeading: 'تم تحديث كلمة المرور بنجاح',
  resetPasswordSubHeading: 'بإمكانك الرجوع للخلف لتسجيل الدخول لحسابك',
  goBackToHome: 'الرجوع للصفحة الرئيسية',
  resetPassword: 'اعادة تعيين كلمة المرور',
  resetPasswordRequired: 'لابد من ادخال كلمة المرور',
  passwordNotMatch: 'تأكيد كلمة المرور لاتطابق كلمة المرور',
};
