export const Images = {
  logo: require('./logo.svg'),
  qrLogo: require('./qr_logo.png'),
  notFoundUser: require('./notfound-user.svg'),
  userAvatar: require('./user-avatar.svg'),
  dineIn: require('./dineIn.svg'),
  pickup: require('./pickup.svg'),
  delivery: require('./delivery.svg'),
  applePay: require('./apple.svg'),
  visa: require('./visa.svg'),
  mada: require('./mada.png'),
  mastercard: require('./mastercard.svg'),
  testOrder: require('./testorder.svg'),
  payAtStore: require('./payatstore.svg'),
  cash: require('./cash.svg'),
  americanexpress: require('./americanexpress.svg'),
  noCustomers: require('./no-customers.svg'),
  notPaidOrder: require('./no-order-2.svg'),
  noMenu: require('./no-menu.svg'),
  noLinks: require('./no-links.svg'),
  noStore: require('./no-store.svg'),
  noPosLogin: require('./pos-login.svg'),
  noOrdersPaid: require('./no-order-paid.svg'),
  loginSignup: require('./login-signup.svg'),
  ETA: require('./eta.svg'),
  stop: require('./stop.svg'),
  fleetManagement: require('./fleetManagement.svg'),
  noFleetManagement: require('./noFleetManagement.svg'),
  inStorePickup: require('./inStorePickup.svg'),
  driveThrough: require('./driveThrough.svg'),
  editIcon: require('./pencil.svg'),
  editPencil: require('./edit-pencil.svg'),
  deleteIcon: require('./delete-menu.svg'),
  settings: require('./settings.svg'),
  addCategory: require('./addCategory.svg'),
  totalMessages: require('./chatting.svg'),
  totalOrders: require('./sent.svg'),
  placeholder: require('./placeholder.svg'),
  placeholderMenuItem: require('./menuItemPlaceholder.svg'),
  emptyPOS: require('./emptyPOS.svg'),
  appleStore: require('./appleStore.svg'),
  googleStore: require('./googleStore.svg'),
};
