export const en = {
  customers: 'Customers',
  customerName: 'Customer',
  date: 'Date',
  more: 'More',
  loadMore: 'Show More',
  totalOrders: 'Total Orders',
  totalOrderValue: 'Total Sales',
  lastSeen: 'Last Seen',
  noCustomers: 'No Customers',
  customerDetails: 'Customer Details',
  customerOrders: 'Customer Orders',
  unknown: 'Unknown',
  firstSeen: 'First Seen',
  firstSeenInfo: 'Customer Was First Seen On',
  orderValueInfo: 'Total value of orders',
  orderTotalInfo: 'Total count of orders',
  noCustomersPlaceHolder:
    'Once you receive online orders, you will start seeing customers here',
};

export const ar: typeof en = {
  customers: 'العملاء',
  customerName: 'اسم العميل',
  orderValueInfo: 'مجموع المدفوعات',
  orderTotalInfo: 'عدد الطلبات',
  firstSeen: 'أول زيارة',
  firstSeenInfo: 'أول طلب أو زيارة للموقع',
  date: 'التاريخ',
  more: 'المزيد',
  loadMore: 'عرض المزيد',
  totalOrders: 'مجموع الطلبات',
  totalOrderValue: 'مجموع المشتريات',
  lastSeen: 'اخر زيارة',
  noCustomers: 'لايوجد عملاء',
  customerDetails: 'تفاصيل العميل',
  customerOrders: 'طلبات العميل',
  unknown: 'مجهول',
  noCustomersPlaceHolder: 'بعد استلامك للطلبات، سيظهر العملاء هنا',
};
