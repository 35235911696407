import {
  Box,
  createStyles,
  Divider,
  Link,
  makeStyles,
  MenuItem,
  Theme,
  Typography,
} from '@material-ui/core';
import ExitToApp from '@material-ui/icons/ExitToApp';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import PersonIcon from '@material-ui/icons/Person';
import { Link as LinkRouter } from '@reach/router';
import React from 'react';
import { t } from 'src/i18n/translate';
import { useHandleLogout } from 'src/sidemenu/hooks/useHandleLogout';

import { MemberShipDetails } from '../../MemberShipDetails';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    smallDot: {
      width: theme.spacing(1),
      height: theme.spacing(1),
      margin: '0px 10px',
    },
    menuItem: {
      padding: theme.spacing(1.5),
      display: 'flex',
      alignItems: 'end',
    },
  }),
);

export const TopBarDropdown = () => {
  const classes = useStyles();
  const { handleLogout } = useHandleLogout();
  return (
    <div>
      <MenuItem>
        <MemberShipDetails />
      </MenuItem>
      <Divider />
      <MenuItem
        component={LinkRouter}
        to="/account"
        className={classes.menuItem}>
        <PersonIcon className="mr-2" />
        <Typography variant="subtitle1">{t('account:account')}</Typography>
      </MenuItem>

      <MenuItem onClick={handleLogout} className={classes.menuItem}>
        <ExitToApp className="mr-2" />
        <Typography variant="subtitle1">{t('common:logout')}</Typography>
      </MenuItem>
      <Divider />
      <MenuItem className={classes.menuItem} button={false}>
        <Box
          width={1}
          display="flex"
          alignItems="center"
          justifyContent="center">
          <Link href={process.env.REACT_APP_PRIVACY_POLICY_URL} target="_blank">
            <Typography variant="caption" color="textSecondary">
              {t('common:privacyPolicy')}
            </Typography>
          </Link>

          <FiberManualRecordIcon
            className={classes.smallDot}
            color="disabled"
          />

          <Link href={process.env.REACT_APP_TERMS_OF_USE_URL} target="_blank">
            <Typography variant="caption" color="textSecondary">
              {t('common:termsOfService')}
            </Typography>
          </Link>
        </Box>
      </MenuItem>
    </div>
  );
};
