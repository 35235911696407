import { Language } from './uiTypes';

export const Languages: Language[] = [
  { code: 'en', name: 'English', nativeName: 'English', dir: 'ltr' },
  { code: 'ab', name: 'Abkhaz', nativeName: 'аҧсуа', dir: 'ltr' },
  { code: 'aa', name: 'Afar', nativeName: 'Afaraf', dir: 'ltr' },
  { code: 'af', name: 'Afrikaans', nativeName: 'Afrikaans', dir: 'ltr' },
  { code: 'ak', name: 'Akan', nativeName: 'Akan', dir: 'ltr' },
  { code: 'sq', name: 'Albanian', nativeName: 'Shqip', dir: 'ltr' },
  { code: 'am', name: 'Amharic', nativeName: 'አማርኛ', dir: 'ltr' },
  { code: 'ar', name: 'Arabic', nativeName: 'العربية', dir: 'rtl' },
  { code: 'an', name: 'Aragonese', nativeName: 'Aragonés', dir: 'ltr' },
  { code: 'hy', name: 'Armenian', nativeName: 'Հայերեն', dir: 'ltr' },
  { code: 'as', name: 'Assamese', nativeName: 'অসমীয়া', dir: 'ltr' },
  {
    code: 'av',
    name: 'Avaric',
    nativeName: 'авар мацӀ, магӀарул мацӀ',
    dir: 'ltr',
  },
  { code: 'ae', name: 'Avestan', nativeName: 'avesta', dir: 'ltr' },
  { code: 'ay', name: 'Aymara', nativeName: 'aymar aru', dir: 'ltr' },
  {
    code: 'az',
    name: 'Azerbaijani',
    nativeName: 'azərbaycan dili',
    dir: 'ltr',
  },
  { code: 'bm', name: 'Bambara', nativeName: 'bamanankan', dir: 'ltr' },
  { code: 'ba', name: 'Bashkir', nativeName: 'башҡорт теле', dir: 'ltr' },
  { code: 'eu', name: 'Basque', nativeName: 'euskara, euskera', dir: 'ltr' },
  { code: 'be', name: 'Belarusian', nativeName: 'Беларуская', dir: 'ltr' },
  { code: 'bn', name: 'Bengali', nativeName: 'বাংলা', dir: 'ltr' },
  { code: 'bh', name: 'Bihari', nativeName: 'भोजपुरी', dir: 'ltr' },
  { code: 'bi', name: 'Bislama', nativeName: 'Bislama', dir: 'ltr' },
  { code: 'bs', name: 'Bosnian', nativeName: 'bosanski jezik', dir: 'ltr' },
  { code: 'br', name: 'Breton', nativeName: 'brezhoneg', dir: 'ltr' },
  { code: 'bg', name: 'Bulgarian', nativeName: 'български език', dir: 'ltr' },
  { code: 'my', name: 'Burmese', nativeName: 'ဗမာစာ', dir: 'ltr' },
  { code: 'ca', name: 'Catalan; Valencian', nativeName: 'Català', dir: 'ltr' },
  { code: 'ch', name: 'Chamorro', nativeName: 'Chamoru', dir: 'ltr' },
  { code: 'ce', name: 'Chechen', nativeName: 'нохчийн мотт', dir: 'ltr' },
  {
    code: 'ny',
    name: 'Chichewa; Chewa; Nyanja',
    nativeName: 'chiCheŵa, chinyanja',
    dir: 'ltr',
  },
  {
    code: 'zh',
    name: 'Chinese',
    nativeName: '中文 (Zhōngwén), 汉语, 漢語',
    dir: 'ltr',
  },
  { code: 'cv', name: 'Chuvash', nativeName: 'чӑваш чӗлхи', dir: 'ltr' },
  { code: 'kw', name: 'Cornish', nativeName: 'Kernewek', dir: 'ltr' },
  {
    code: 'co',
    name: 'Corsican',
    nativeName: 'corsu, lingua corsa',
    dir: 'ltr',
  },
  { code: 'cr', name: 'Cree', nativeName: 'ᓀᐦᐃᔭᐍᐏᐣ', dir: 'ltr' },
  { code: 'hr', name: 'Croatian', nativeName: 'hrvatski', dir: 'ltr' },
  { code: 'cs', name: 'Czech', nativeName: 'česky, čeština', dir: 'ltr' },
  { code: 'da', name: 'Danish', nativeName: 'dansk', dir: 'ltr' },
  {
    code: 'dv',
    name: 'Divehi; Dhivehi; Maldivian;',
    nativeName: 'ދިވެހި',
    dir: 'ltr',
  },
  { code: 'nl', name: 'Dutch', nativeName: 'Nederlands, Vlaams', dir: 'ltr' },

  { code: 'eo', name: 'Esperanto', nativeName: 'Esperanto', dir: 'ltr' },
  { code: 'et', name: 'Estonian', nativeName: 'eesti, eesti keel', dir: 'ltr' },
  { code: 'ee', name: 'Ewe', nativeName: 'Eʋegbe', dir: 'ltr' },
  { code: 'fo', name: 'Faroese', nativeName: 'føroyskt', dir: 'ltr' },
  { code: 'fj', name: 'Fijian', nativeName: 'vosa Vakaviti', dir: 'ltr' },
  {
    code: 'fi',
    name: 'Finnish',
    nativeName: 'suomi, suomen kieli',
    dir: 'ltr',
  },
  {
    code: 'fr',
    name: 'French',
    nativeName: 'français, langue française',
    dir: 'ltr',
  },
  {
    code: 'ff',
    name: 'Fula; Fulah; Pulaar; Pular',
    nativeName: 'Fulfulde, Pulaar, Pular',
    dir: 'ltr',
  },
  { code: 'gl', name: 'Galician', nativeName: 'Galego', dir: 'ltr' },
  { code: 'ka', name: 'Georgian', nativeName: 'ქართული', dir: 'ltr' },
  { code: 'de', name: 'German', nativeName: 'Deutsch', dir: 'ltr' },
  { code: 'el', name: 'Greek, Modern', nativeName: 'Ελληνικά', dir: 'ltr' },
  { code: 'gn', name: 'Guaraní', nativeName: 'Avañeẽ', dir: 'ltr' },
  { code: 'gu', name: 'Gujarati', nativeName: 'ગુજરાતી', dir: 'ltr' },
  {
    code: 'ht',
    name: 'Haitian; Haitian Creole',
    nativeName: 'Kreyòl ayisyen',
    dir: 'ltr',
  },
  { code: 'ha', name: 'Hausa', nativeName: 'Hausa, هَوُسَ', dir: 'ltr' },
  { code: 'he', name: 'Hebrew (modern)', nativeName: 'עברית', dir: 'rtl' },
  { code: 'hz', name: 'Herero', nativeName: 'Otjiherero', dir: 'ltr' },
  { code: 'hi', name: 'Hindi', nativeName: 'हिन्दी, हिंदी', dir: 'ltr' },
  { code: 'ho', name: 'Hiri Motu', nativeName: 'Hiri Motu', dir: 'ltr' },
  { code: 'hu', name: 'Hungarian', nativeName: 'Magyar', dir: 'ltr' },
  { code: 'ia', name: 'Interlingua', nativeName: 'Interlingua', dir: 'ltr' },
  {
    code: 'id',
    name: 'Indonesian',
    nativeName: 'Bahasa Indonesia',
    dir: 'ltr',
  },
  {
    code: 'ie',
    name: 'Interlingue',
    nativeName: 'Originally called Occidental; then Interlingue after WWII',
    dir: 'ltr',
  },
  { code: 'ga', name: 'Irish', nativeName: 'Gaeilge', dir: 'ltr' },
  { code: 'ig', name: 'Igbo', nativeName: 'Asụsụ Igbo', dir: 'ltr' },
  { code: 'ik', name: 'Inupiaq', nativeName: 'Iñupiaq, Iñupiatun', dir: 'ltr' },
  { code: 'io', name: 'Ido', nativeName: 'Ido', dir: 'ltr' },
  { code: 'is', name: 'Icelandic', nativeName: 'Íslenska', dir: 'ltr' },
  { code: 'it', name: 'Italian', nativeName: 'Italiano', dir: 'ltr' },
  { code: 'iu', name: 'Inuktitut', nativeName: 'ᐃᓄᒃᑎᑐᑦ', dir: 'ltr' },
  {
    code: 'ja',
    name: 'Japanese',
    nativeName: '日本語 (にほんご／にっぽんご)',
    dir: 'ltr',
  },
  { code: 'jv', name: 'Javanese', nativeName: 'basa Jawa', dir: 'ltr' },
  {
    code: 'kl',
    name: 'Kalaallisut, Greenlandic',
    nativeName: 'kalaallisut, kalaallit oqaasii',
    dir: 'ltr',
  },
  { code: 'kn', name: 'Kannada', nativeName: 'ಕನ್ನಡ', dir: 'ltr' },
  { code: 'kr', name: 'Kanuri', nativeName: 'Kanuri', dir: 'ltr' },
  { code: 'ks', name: 'Kashmiri', nativeName: 'कश्मीरी, كشميري‎', dir: 'ltr' },
  { code: 'kk', name: 'Kazakh', nativeName: 'Қазақ тілі', dir: 'ltr' },
  { code: 'km', name: 'Khmer', nativeName: 'ភាសាខ្មែរ', dir: 'ltr' },
  { code: 'ki', name: 'Kikuyu, Gikuyu', nativeName: 'Gĩkũyũ', dir: 'ltr' },
  { code: 'rw', name: 'Kinyarwanda', nativeName: 'Ikinyarwanda', dir: 'ltr' },
  {
    code: 'ky',
    name: 'Kirghiz, Kyrgyz',
    nativeName: 'кыргыз тили',
    dir: 'ltr',
  },
  { code: 'kv', name: 'Komi', nativeName: 'коми кыв', dir: 'ltr' },
  { code: 'kg', name: 'Kongo', nativeName: 'KiKongo', dir: 'ltr' },
  {
    code: 'ko',
    name: 'Korean',
    nativeName: '한국어 (韓國語), 조선말 (朝鮮語)',
    dir: 'ltr',
  },
  { code: 'ku', name: 'Kurdish', nativeName: 'Kurdî, كوردی‎', dir: 'rtl' },
  {
    code: 'kj',
    name: 'Kwanyama, Kuanyama',
    nativeName: 'Kuanyama',
    dir: 'ltr',
  },
  {
    code: 'la',
    name: 'Latin',
    nativeName: 'latine, lingua latina',
    dir: 'ltr',
  },
  {
    code: 'lb',
    name: 'Luxembourgish, Letzeburgesch',
    nativeName: 'Lëtzebuergesch',
    dir: 'ltr',
  },
  { code: 'lg', name: 'Luganda', nativeName: 'Luganda', dir: 'ltr' },
  {
    code: 'li',
    name: 'Limburgish, Limburgan, Limburger',
    nativeName: 'Limburgs',
    dir: 'ltr',
  },
  { code: 'ln', name: 'Lingala', nativeName: 'Lingála', dir: 'ltr' },
  { code: 'lo', name: 'Lao', nativeName: 'ພາສາລາວ', dir: 'ltr' },
  { code: 'lt', name: 'Lithuanian', nativeName: 'lietuvių kalba', dir: 'ltr' },
  { code: 'lu', name: 'Luba-Katanga', nativeName: '', dir: 'ltr' },
  { code: 'lv', name: 'Latvian', nativeName: 'latviešu valoda', dir: 'ltr' },
  { code: 'gv', name: 'Manx', nativeName: 'Gaelg, Gailck', dir: 'ltr' },
  {
    code: 'mk',
    name: 'Macedonian',
    nativeName: 'македонски јазик',
    dir: 'ltr',
  },
  { code: 'mg', name: 'Malagasy', nativeName: 'Malagasy fiteny', dir: 'ltr' },
  {
    code: 'ms',
    name: 'Malay',
    nativeName: 'bahasa Melayu, بهاس ملايو‎',
    dir: 'ltr',
  },
  { code: 'ml', name: 'Malayalam', nativeName: 'മലയാളം', dir: 'ltr' },
  { code: 'mt', name: 'Maltese', nativeName: 'Malti', dir: 'ltr' },
  { code: 'mi', name: 'Māori', nativeName: 'te reo Māori', dir: 'ltr' },
  { code: 'mr', name: 'Marathi (Marāṭhī)', nativeName: 'मराठी', dir: 'ltr' },
  { code: 'mh', name: 'Marshallese', nativeName: 'Kajin M̧ajeļ', dir: 'ltr' },
  { code: 'mn', name: 'Mongolian', nativeName: 'монгол', dir: 'ltr' },
  { code: 'na', name: 'Nauru', nativeName: 'Ekakairũ Naoero', dir: 'ltr' },
  {
    code: 'nv',
    name: 'Navajo, Navaho',
    nativeName: 'Diné bizaad, Dinékʼehǰí',
    dir: 'ltr',
  },
  {
    code: 'nb',
    name: 'Norwegian Bokmål',
    nativeName: 'Norsk bokmål',
    dir: 'ltr',
  },
  { code: 'nd', name: 'North Ndebele', nativeName: 'isiNdebele', dir: 'ltr' },
  { code: 'ne', name: 'Nepali', nativeName: 'नेपाली', dir: 'ltr' },
  { code: 'ng', name: 'Ndonga', nativeName: 'Owambo', dir: 'ltr' },
  {
    code: 'nn',
    name: 'Norwegian Nynorsk',
    nativeName: 'Norsk nynorsk',
    dir: 'ltr',
  },
  { code: 'no', name: 'Norwegian', nativeName: 'Norsk', dir: 'ltr' },
  { code: 'ii', name: 'Nuosu', nativeName: 'ꆈꌠ꒿ Nuosuhxop', dir: 'ltr' },
  { code: 'nr', name: 'South Ndebele', nativeName: 'isiNdebele', dir: 'ltr' },
  { code: 'oc', name: 'Occitan', nativeName: 'Occitan', dir: 'ltr' },
  { code: 'oj', name: 'Ojibwe, Ojibwa', nativeName: 'ᐊᓂᔑᓈᐯᒧᐎᓐ', dir: 'ltr' },
  {
    code: 'cu',
    name:
      'Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic',
    nativeName: 'ѩзыкъ словѣньскъ',
    dir: 'ltr',
  },
  { code: 'om', name: 'Oromo', nativeName: 'Afaan Oromoo', dir: 'ltr' },
  { code: 'or', name: 'Oriya', nativeName: 'ଓଡ଼ିଆ', dir: 'ltr' },
  {
    code: 'os',
    name: 'Ossetian, Ossetic',
    nativeName: 'ирон æвзаг',
    dir: 'ltr',
  },
  {
    code: 'pa',
    name: 'Panjabi, Punjabi',
    nativeName: 'ਪੰਜਾਬੀ, پنجابی‎',
    dir: 'ltr',
  },
  { code: 'pi', name: 'Pāli', nativeName: 'पाऴि', dir: 'ltr' },
  { code: 'fa', name: 'Persian', nativeName: 'فارسی', dir: 'rtl' },
  { code: 'pl', name: 'Polish', nativeName: 'polski', dir: 'ltr' },
  { code: 'ps', name: 'Pashto, Pushto', nativeName: 'پښتو', dir: 'ltr' },
  { code: 'pt', name: 'Portuguese', nativeName: 'Português', dir: 'ltr' },
  { code: 'qu', name: 'Quechua', nativeName: 'Runa Simi, Kichwa', dir: 'ltr' },
  { code: 'rm', name: 'Romansh', nativeName: 'rumantsch grischun', dir: 'ltr' },
  { code: 'rn', name: 'Kirundi', nativeName: 'kiRundi', dir: 'ltr' },
  {
    code: 'ro',
    name: 'Romanian, Moldavian, Moldovan',
    nativeName: 'română',
    dir: 'ltr',
  },
  { code: 'ru', name: 'Russian', nativeName: 'русский язык', dir: 'ltr' },
  {
    code: 'sa',
    name: 'Sanskrit (Saṁskṛta)',
    nativeName: 'संस्कृतम्',
    dir: 'ltr',
  },
  { code: 'sc', name: 'Sardinian', nativeName: 'sardu', dir: 'ltr' },
  {
    code: 'sd',
    name: 'Sindhi',
    nativeName: 'सिन्धी, سنڌي، سندھی‎',
    dir: 'ltr',
  },
  {
    code: 'se',
    name: 'Northern Sami',
    nativeName: 'Davvisámegiella',
    dir: 'ltr',
  },
  { code: 'sm', name: 'Samoan', nativeName: 'gagana faa Samoa', dir: 'ltr' },
  { code: 'sg', name: 'Sango', nativeName: 'yângâ tî sängö', dir: 'ltr' },
  { code: 'sr', name: 'Serbian', nativeName: 'српски језик', dir: 'ltr' },
  {
    code: 'gd',
    name: 'Scottish Gaelic; Gaelic',
    nativeName: 'Gàidhlig',
    dir: 'ltr',
  },
  { code: 'sn', name: 'Shona', nativeName: 'chiShona', dir: 'ltr' },
  { code: 'si', name: 'Sinhala, Sinhalese', nativeName: 'සිංහල', dir: 'ltr' },
  { code: 'sk', name: 'Slovak', nativeName: 'slovenčina', dir: 'ltr' },
  { code: 'sl', name: 'Slovene', nativeName: 'slovenščina', dir: 'ltr' },
  {
    code: 'so',
    name: 'Somali',
    nativeName: 'Soomaaliga, af Soomaali',
    dir: 'ltr',
  },
  { code: 'st', name: 'Southern Sotho', nativeName: 'Sesotho', dir: 'ltr' },
  {
    code: 'es',
    name: 'Spanish; Castilian',
    nativeName: 'español, castellano',
    dir: 'ltr',
  },
  { code: 'su', name: 'Sundanese', nativeName: 'Basa Sunda', dir: 'ltr' },
  { code: 'sw', name: 'Swahili', nativeName: 'Kiswahili', dir: 'ltr' },
  { code: 'ss', name: 'Swati', nativeName: 'SiSwati', dir: 'ltr' },
  { code: 'sv', name: 'Swedish', nativeName: 'svenska', dir: 'ltr' },
  { code: 'ta', name: 'Tamil', nativeName: 'தமிழ்', dir: 'ltr' },
  { code: 'te', name: 'Telugu', nativeName: 'తెలుగు', dir: 'ltr' },
  {
    code: 'tg',
    name: 'Tajik',
    nativeName: 'тоҷикӣ, toğikī, تاجیکی‎',
    dir: 'ltr',
  },
  { code: 'th', name: 'Thai', nativeName: 'ไทย', dir: 'ltr' },
  { code: 'ti', name: 'Tigrinya', nativeName: 'ትግርኛ', dir: 'ltr' },
  {
    code: 'bo',
    name: 'Tibetan Standard, Tibetan, Central',
    nativeName: 'བོད་ཡིག',
    dir: 'ltr',
  },
  { code: 'tk', name: 'Turkmen', nativeName: 'Türkmen, Түркмен', dir: 'ltr' },
  {
    code: 'tl',
    name: 'Tagalog',
    nativeName: 'Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔',
    dir: 'ltr',
  },
  { code: 'tn', name: 'Tswana', nativeName: 'Setswana', dir: 'ltr' },
  {
    code: 'to',
    name: 'Tonga (Tonga Islands)',
    nativeName: 'faka Tonga',
    dir: 'ltr',
  },
  { code: 'tr', name: 'Turkish', nativeName: 'Türkçe', dir: 'ltr' },
  { code: 'ts', name: 'Tsonga', nativeName: 'Xitsonga', dir: 'ltr' },
  {
    code: 'tt',
    name: 'Tatar',
    nativeName: 'татарча, tatarça, تاتارچا‎',
    dir: 'ltr',
  },
  { code: 'tw', name: 'Twi', nativeName: 'Twi', dir: 'ltr' },
  { code: 'ty', name: 'Tahitian', nativeName: 'Reo Tahiti', dir: 'ltr' },
  {
    code: 'ug',
    name: 'Uighur, Uyghur',
    nativeName: 'Uyƣurqə, ئۇيغۇرچە‎',
    dir: 'ltr',
  },
  { code: 'uk', name: 'Ukrainian', nativeName: 'українська', dir: 'ltr' },
  { code: 'ur', name: 'Urdu', nativeName: 'اردو', dir: 'rtl' },
  { code: 'uz', name: 'Uzbek', nativeName: 'zbek, Ўзбек, أۇزبېك‎', dir: 'ltr' },
  { code: 've', name: 'Venda', nativeName: 'Tshivenḓa', dir: 'ltr' },
  { code: 'vi', name: 'Vietnamese', nativeName: 'Tiếng Việt', dir: 'ltr' },
  { code: 'vo', name: 'Volapük', nativeName: 'Volapük', dir: 'ltr' },
  { code: 'wa', name: 'Walloon', nativeName: 'Walon', dir: 'ltr' },
  { code: 'cy', name: 'Welsh', nativeName: 'Cymraeg', dir: 'ltr' },
  { code: 'wo', name: 'Wolof', nativeName: 'Wollof', dir: 'ltr' },
  { code: 'fy', name: 'Western Frisian', nativeName: 'Frysk', dir: 'ltr' },
  { code: 'xh', name: 'Xhosa', nativeName: 'isiXhosa', dir: 'ltr' },
  { code: 'yi', name: 'Yiddish', nativeName: 'ייִדיש', dir: 'ltr' },
  { code: 'yo', name: 'Yoruba', nativeName: 'Yorùbá', dir: 'ltr' },
  {
    code: 'za',
    name: 'Zhuang, Chuang',
    nativeName: 'Saɯ cueŋƅ, Saw cuengh',
    dir: 'ltr',
  },
];
export const DefaultLanguage = Languages.find(lang => lang.code === 'en');
