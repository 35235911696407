import { Merchant } from 'src/translations/apiTypes';
import { Languages } from 'src/translations/consts';
import { Language, MerchantUI } from 'src/translations/uiTypes';

export const addLanguage = (
  merchant: Merchant,
  lang: Opt<Language>,
): Merchant => {
  const isAlreadyInSupportedLanguages = merchant.languages.supported.filter(
    language => language === lang?.code,
  );
  if (isAlreadyInSupportedLanguages.length < 1 && lang?.code) {
    merchant.languages.supported.push(lang.code);
    return merchant;
  } else {
    return merchant;
  }
};

export const removeLanguage = (
  merchant: Merchant,
  lang: Language | undefined,
): Merchant => {
  const newLanguages = merchant.languages.supported.filter(
    item => item !== lang?.code,
  );
  merchant.languages.supported = newLanguages;
  return merchant;
};

export const makePrimaryLanguage = (
  merchant: Merchant,
  lang: Language | undefined,
): Merchant => {
  merchant.languages.primary = lang?.code ?? Languages[0].code;
  return merchant;
};

export const disableLanguage = (
  merchant: MerchantUI,
  lang: Language | undefined,
): MerchantUI => {
  const isAlreadyInDisabledLanguages = merchant.languages.disabled.filter(
    language => language === lang,
  );
  if (isAlreadyInDisabledLanguages.length < 1) {
    merchant.languages.disabled.push(lang);
    return merchant;
  } else {
    return merchant;
  }
};

export const enableLanguage = (
  merchant: MerchantUI,
  lang: Language | undefined,
): MerchantUI => {
  const newLanguages = merchant.languages.disabled.filter(
    item => item !== lang,
  );
  merchant.languages.disabled = newLanguages;
  return merchant;
};

export const toggleDisabledLanguage = (
  merchant: Merchant,
  lang: Language | undefined,
) => {
  const isAlreadyInDisabledLanguages = merchant.languages.disabled?.filter(
    language => language === lang?.code,
  );
  if (
    isAlreadyInDisabledLanguages &&
    isAlreadyInDisabledLanguages.length < 1 &&
    lang?.code
  ) {
    merchant.languages.disabled?.push(lang?.code);
    return merchant;
  } else {
    const newLanguages = merchant.languages.disabled?.filter(
      item => item !== lang?.code,
    );
    merchant.languages.disabled = newLanguages;
    return merchant;
  }
};
