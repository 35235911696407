import { CircularProgress, createStyles, makeStyles } from '@material-ui/core';
import React, { memo } from 'react';

interface Props {
  size?: number;
}

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      flex: 1,
      display: 'flex',
      margin: '0px 10px',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
);

export const Loader = memo<Props>(({ size = 40 }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <CircularProgress size={size} />
    </div>
  );
});
