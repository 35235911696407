import { SnackbarOrigin } from '@material-ui/core';
import create from 'zustand';

export type AlertType = 'success' | 'error' | 'warning' | 'info';

export interface AlertProps {
  alertIsOpen: boolean;
  alertType?: AlertType;
  alertMessage?: string | undefined;
  closeAlert?: () => void;
  autoHide?: boolean;
  hideClose?: boolean;
  anchorOrigin?: SnackbarOrigin;
  setAlert: (type: AlertType, message: string | undefined) => void;
}

export const [useAlertStore] = create<AlertProps>(set => ({
  alertType: undefined,
  alertIsOpen: false,
  alertMessage: '',
  setAlert: (type, message) =>
    set({ alertIsOpen: true, alertType: type, alertMessage: message }),
  closeAlert: () => set({ alertIsOpen: false }),
}));
