import uuid from 'uuid';
import create from 'zustand';

interface TranslationsStates {
  isOpen: boolean;
  toggleDialog: () => void;
  closeDialog: () => void;
  isUpdated: boolean;
  setUpdated: () => void;
  updatedKey: string;
}

export const [useTranslationsStore] = create<TranslationsStates>(
  (set, get) => ({
    isOpen: false,
    isUpdated: false,
    updatedKey: uuid(),
    toggleDialog: () =>
      set({
        isOpen: !get().isOpen,
      }),
    closeDialog: () =>
      set({
        isOpen: false,
      }),
    setUpdated: () =>
      set({
        isUpdated: true,
        updatedKey: uuid(),
      }),
  }),
);
