/* eslint-disable @typescript-eslint/no-explicit-any */

import axios from 'axios';
import ApiError from 'src/api/ApiError';
import { CustomerAPI, Customers } from 'src/customers/apiTypes';
import {
  CreateLink,
  Device,
  DeviceTest,
  Link,
  OperatorCredentials,
  OperatorDetails,
  OperatorStoresUpdateDetails,
} from 'src/devices/apiTypes';
import { UploadType } from 'src/menu/apiEnums';
import {
  Menu,
  MenuCategory,
  MenuItem,
  MenuItemOptions,
  PreSignedUrl,
  UpdateMenuItemPayload,
} from 'src/menu/apiTypes';
import { AssignDriver, Courier, Order, OrdersData } from 'src/orders/apiTypes';
import { AcceptOrderEntry, RejectOrderEntry } from 'src/orders/uiTypes';
import { StoreSettings, UpdateSettings } from 'src/settings/apiTypes';
import { PreSignupDetails, SignupDetails } from 'src/signup/apiTypes';
import { CreateStore, Store, UpdateStoreSettings } from 'src/stores/apiTypes';
import { Merchant } from 'src/translations/apiTypes';
import {
  Account,
  Token,
  User,
  UserRegistration,
  Version,
} from 'src/user/apiTypes';
import DateHelper from 'src/utils/DateHelper';
import MXCookies from 'src/utils/MXCookies';
import StringsHelper from 'src/utils/StringsHelper';

const SessionId = StringsHelper.generateGuid();
axios.defaults.baseURL = process.env.REACT_APP_API_URL!;
axios.defaults.headers.common['X-Foodbit-SessionId'] = SessionId;

export class Api {
  public API_URL = process.env.REACT_APP_API_URL!;
  public API_CLIENT_KEY = process.env.REACT_APP_API_CLIENT_KEY;
  public API_SECRET_KEY = process.env.REACT_APP_API_SECRET_KEY;

  /**
   * Generate a random id as a sessionId every time you create an instance of this class
   * @type {string}
   */
  public sessionId: string = StringsHelper.generateGuid();

  /**
   * The date when the access_token will expire and will no longer be valid.
   *
   * @type {null}
   */
  private tokenExpirationDate: Opt<Date> = undefined;

  private token: Opt<Token> = {
    access_token: '',
    token_type: '',
    refresh_token: '',
    expires_in: 0,
  };

  /**
   * True when the token is being refreshed.
   * @type {boolean}
   */
  private refreshing = false;

  /**
   * True if there is a token and it has expired and false otherwise.
   *
   * @returns {boolean|*}
   */
  public async isTokenExpired(): Promise<boolean> {
    if (this.tokenExpirationDate == null) {
      this.tokenExpirationDate = await MXCookies.getTokenExpirationDate();
    }
    const isExpired =
      this.token != null &&
      this.tokenExpirationDate != null &&
      DateHelper.isDateExpired(this.tokenExpirationDate);
    return isExpired;
  }

  /**
   * Load access token if it's stored in MXCookies.
   *
   * @returns {Promise<*>}
   */
  public loadAccessToken(): Token {
    if (this.token == null || this.token.access_token === '') {
      this.token = MXCookies.getAccessToken();
    }
    if (!this.token?.access_token) {
      throw Error('token is not present');
    }

    axios.defaults.headers.common.authorization = `Bearer ${this.token.access_token}`;

    return this.token;
  }

  /**
   * Get the access token if it's stored in MXCookies.
   *
   * @returns The access token or null {Promise<*>}
   */
  public async getAccessToken(): Promise<Opt<Token>> {
    let token;
    try {
      token = this.loadAccessToken();
    } catch (e) {
      console.error(e.message);
    }
    return token;
  }

  /**
   * Refreshes the token and stores it once refreshed.
   *
   * @returns {Promise<void>}
   */
  public async refreshToken(): Promise<any> {
    this.refreshing = true;

    this.loadAccessToken();

    try {
      const response = await axios({
        method: 'post',
        url: '/oauth/token',
        params: {
          refresh_token: (this.token && this.token.refresh_token) || null,
          grant_type: 'refresh_token',
        },
      });
      if (response.status === 200) {
        await this.saveAccessToken(response.data);
      }

      return response.data;
    } catch (error) {
      throw new ApiError(error);
    }
  }

  /**
   * Save the new access token.
   *
   * @param token new access token
   */
  public async saveAccessToken(token: Token) {
    this.token = token;
    await MXCookies.setAccessToken(this.token);
    this.tokenExpirationDate = DateHelper.addSecondsToToday(
      this.token.expires_in,
    );
    await MXCookies.setTokenExpirationDate(this.tokenExpirationDate);
  }

  /**
   * Login user using username and password. The login will store the access_token into MXCookies.
   *
   * @param username
   * @param password
   * @returns {Promise<void>}
   */
  public async loginUser(
    username: string,
    password: string,
  ): Promise<Opt<Token>> {
    try {
      const headers = {
        Authorization: `Basic ${StringsHelper.encodeToB64(
          `${this.API_CLIENT_KEY}:${this.API_SECRET_KEY}`,
        )}`,
      };

      const response = await axios({
        headers,
        method: 'post',
        url: `/oauth/token?username=${encodeURIComponent(
          username,
        )}&password=${encodeURIComponent(password)}&grant_type=password`,
      });
      // Store the token
      if (response) {
        this.saveAccessToken(response.data);
        return response.data;
      }
      return undefined;
    } catch (error) {
      throw new ApiError(error);
    }
  }

  /**
   * Register a new user
   *
   * @param registration: User object
   * @returns {Promise<void>}
   */
  public async registerUser(registration: UserRegistration): Promise<User> {
    try {
      const headers = {
        Authorization: `Basic ${StringsHelper.encodeToB64(
          `${this.API_CLIENT_KEY}:${this.API_SECRET_KEY}`,
        )}`,
      };

      const response = await axios({
        headers,
        method: 'post',
        url: '/users',
        data: registration,
      });
      return response.data;
    } catch (error) {
      throw new ApiError(error);
    }
  }

  /**
   * Fetch All Stores
   *
   * @param key: query string
   * @param merchantId : string
   * @returns {Promise<Store[]>}
   */
  fetchAllStores = async (merchantId: string): Promise<Store[]> => {
    this.loadAccessToken();
    const apiCall = axios({
      method: 'get',
      url: `/merchants/${merchantId}/stores`,
    });

    return this.executeApiCall(0, 'fetchAllStores', true, apiCall);
  };

  /**
   * Fetch Store by Store ID
   *  The function fetches store details of the specified store id
   * @param (storeId: string) `id` of the store you want to fetch
   * @returns a promise with Store object {Promise<Store>}
   */

  fetchStoreById = async (storeId: string): Promise<Store> => {
    this.loadAccessToken();
    const apiCall = axios({
      method: 'get',
      url: `/v1/stores/${storeId}`,
    });
    return this.executeApiCall(0, 'fetchStoreById', true, apiCall);
  };

  /**
   * Saves new Menu in the system.
   *
   * @param data The complete menu data

   * @returns {Promise<Menu>}
   */
  createMenu = async (data: Partial<Menu>): Promise<Menu> => {
    this.loadAccessToken();
    const apiCall = axios({
      method: 'post',
      url: '/menus',
      data,
    });

    return this.executeApiCall(0, 'createMenu', true, apiCall);
  };

  /**
   * Saves new Menu in the system.
   * @param data tempPassword returned from the email and the newPassword enter
   * by the user
   */

  resetPassword = async (data: {
    tempId: string | null;
    newPassword: string;
  }) => {
    const apiCall = axios({
      method: 'post',
      url: '/users/password/reset',
      data,
    });

    return this.executeApiCall(0, 'resetPassword', true, apiCall);
  };

  /**
   * Save New Store in the system.
   *
   * @param data The complete store data

   * @returns {Promise<Store>}
   */
  createStore = async (data: CreateStore): Promise<Store> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'post',
      url: '/stores',
      data,
    });

    return this.executeApiCall(0, 'createStore', true, apiCall);
  };

  /**
   * Update Existing Store in the system.
   *
   * @param data The complete store data

   * @returns Updated Store Object {Promise<void>}
   */
  updateStore = async (data: Store): Promise<Store> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'put',
      url: '/v1/stores',
      data,
    });

    return this.executeApiCall(0, 'updateStore', true, apiCall);
  };

  /**
   * Save Store settings in the system.
   *
   * @param storeId The store id
   * @param data The StoreSettings
   * @returns {Promise<StoreSettings>}
   */
  createStoreSettings = async ({
    storeId,
    settings: data,
  }: UpdateStoreSettings): Promise<StoreSettings> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'post',
      url: `/v1/stores/${storeId}/settings`,
      data,
    });

    return this.executeApiCall(0, 'createStoreSettings', true, apiCall);
  };

  /**
   * Update Existing Store Settings in the system.
   *
   * @param StoreSettings object with updated data
   * @returns Updated Store Object {Promise<StoreSettings>}
   */
  updateStoreSettings = async ({
    storeId,
    settings: data,
  }: UpdateStoreSettings): Promise<StoreSettings> => {
    this.loadAccessToken();
    const apiCall = axios({
      method: 'put',
      url: `/v1/stores/${storeId}`,
      data,
    });

    return this.executeApiCall(0, 'updateStoreSettings', true, apiCall);
  };

  /**
   * Update the isReceivingOrders value for the store
   *
   * @param id The Store identifier
   * @param isReceivingOrders True if receiving is true and false if not
   * @returns {Promise<Array>}
   */
  updateIsReceivingOrders = async ({
    id,
    isReceivingOrders,
  }: {
    id: string;
    isReceivingOrders: boolean;
  }): Promise<boolean> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'post',
      params: {
        isReceivingOrders,
      },
      url: `/v1/stores/${id}/receive-orders`,
    });

    return this.executeApiCall(0, 'updateIsReceivingOrders', true, apiCall);
  };

  /**
   * Update array of existing stores settings in the system.
   *
   * @param storeSettingsArr Array object with updated data

   * @returns Updated Store Object {Promise<StoreSettings[]>}
   */
  updateStoreSettingsArray = async (
    storeSettingsArr: UpdateStoreSettings[],
  ): Promise<StoreSettings[]> => {
    return Promise.all(
      storeSettingsArr.map(storeSettings =>
        this.updateStoreSettings(storeSettings),
      ),
    );
  };

  /**
   * Delete Store
   * This function takes a store ID and delete the Store from the DB.
   *
   * @param id The Store Location identifier
   * @returns {Promise<Array>}
   */
  deleteStore = async (id: string): Promise<void> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'delete',
      url: `/stores/${id}`,
    });

    return this.executeApiCall(0, 'deleteLocation', true, apiCall);
  };

  /**
   * Fetch All Operators
   * This function fetches all the Devices for the given user
   *
   * @param key: The react query key
   * @param merchantId : string
   * @returns {Promise<Device[]>}
   */

  fetchAllOperatorsDevices = async (
    merchantId: string,
  ): Promise<OperatorDetails[]> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'get',
      url: `/merchants/${merchantId}/operators`,
    });

    return this.executeApiCall(0, 'fetchAllOperatorsDevices', true, apiCall);
  };

  /**
   * Test a Device by sending an order.
   *
   * @param data object with device id, user id, and stores ids
   * @returns {Promise<void>}
   */
  testDevice = async (data: DeviceTest): Promise<void> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'post',
      url: '/orders/test',
      params: {
        deviceId: data.deviceId,
        operatorId: data.operatorId,
        storeIds: data.storeIds.join(','),
      },
    });

    return this.executeApiCall(0, 'testDevice', true, apiCall);
  };

  /**
   * Generate OperatorDetails credentials for new Device
   *
   * @returns {Promise<DeviceCredentials>}
   */
  generateOperatorCredentials = async (): Promise<OperatorCredentials> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'post',
      url: '/operators/',
    });

    return this.executeApiCall(0, 'generateOperatorCredentials', true, apiCall);
  };

  generateOneTimeCode = async (
    userId: string,
  ): Promise<{
    code: string;
  }> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'post',
      url: '/operators/loginCode',
      params: {
        userId,
      },
    });

    return this.executeApiCall(0, 'generateOneTimeCode', true, apiCall);
  };

  /**
   * Assign stores to operator
   *
   * @param storeIds[] object with storeIds
   * @returns {Promise<OperatorCredentials>}
   */

  assignStoresToOperator = async ({
    id,
    storeIds: operatorStoreIds,
  }: OperatorStoresUpdateDetails): Promise<OperatorCredentials> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'post',
      url: `/operators/${id}/stores`,
      params: {
        storeIds: operatorStoreIds.join(','),
      },
    });

    return this.executeApiCall(0, 'saveOperatorStores', true, apiCall);
  };

  /**
   * Remove or un-assign stores to operator
   *
   * @param storeIds[] object with storeIds
   * @returns {Promise<OperatorCredentials>}
   */

  unAssignStoresToOperator = async ({
    id,
    storeIds: operatorStoreIds,
  }: OperatorStoresUpdateDetails): Promise<OperatorCredentials> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'delete',
      url: `/operators/${id}/stores`,
      params: {
        storeIds: operatorStoreIds.join(','),
      },
    });

    return this.executeApiCall(0, 'unAssignStoresToOperator', true, apiCall);
  };

  /**
   * Fetch All Operators
   * This function fetches all the Devices for the given user
   *
   * @param id`: string
   * @param key: The react query key
   * @returns {Promise<OperatorDetails[]>}
   */

  fetchOperatorsDetailsById = async (
    key: string,
    id: string,
  ): Promise<OperatorDetails> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'get',
      url: `/operators/${id}/details`,
    });

    return this.executeApiCall(0, key, true, apiCall);
  };

  /**
   * Create Link
   *
   * @returns {Promise<Link[]>}
   */
  createLink = async (data: CreateLink): Promise<Link> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'post',
      data,
      url: `/links`,
    });

    return this.executeApiCall(0, 'createLink', true, apiCall);
  };

  /**
   * Fetch all links
   *
   * @param key: The react query key
   * @param merchantId : string
   * @returns {Promise<Link[]>}
   */
  fetchLinks = async (key: string, merchantId: string): Promise<Link[]> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'get',
      url: `/merchants/${merchantId}/links`,
    });

    return this.executeApiCall(0, key, true, apiCall);
  };

  /**
   * Fetch specific Operator list of Stores
   *
   * @param key: The react query key
   * @param operatorId: string object
   * @returns {Promise<Store[]>}
   */
  fetchOperatorStores = async (
    key: string,
    operatorId: string,
  ): Promise<Store[]> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'get',
      url: `/operators/${operatorId}/stores`,
    });

    return this.executeApiCall(0, key, true, apiCall);
  };

  /**
   * Fetch specific OperatorDetails list of devices
   *
   * @param key: The react query key
   * @param operatorId: string object
   * @returns {Promise<DeviceDetailsFormValues>}
   */
  fetchOperatorDevices = async (
    key: string,
    operatorId: string,
  ): Promise<Device[]> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'get',
      url: `/operators/${operatorId}/devices`,
    });

    return this.executeApiCall(0, key, true, apiCall);
  };

  /**
   * Update OperatorDetails Information with device name
   *
   * @param data The Operator data object

   * @returns {Promise<Device>}
   */
  updateOperator = async (data: User): Promise<OperatorDetails> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'put',
      url: '/operators',
      data,
    });

    return this.executeApiCall(0, 'updateOperator', true, apiCall);
  };

  updateDevices = async (data: OperatorDetails): Promise<OperatorDetails> => {
    this.loadAccessToken();
    const apiCall = axios({
      method: 'patch',
      url: `/operators/${data.user.id}`,
      data,
    });

    return this.executeApiCall(0, 'updateOperator', true, apiCall);
  };

  /**
   * Delete OperatorDetails
   *
   * @param operatorId : string

   * @returns {Promise<Status>}
   */
  deleteOperator = async (operatorId: string): Promise<void> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'delete',
      url: `/operators/${operatorId}`,
    });

    return this.executeApiCall(0, 'deleteOperator', true, apiCall);
  };

  /**
   * Fetch Pre Signup details
   * This function fetches all the PreSignup Details for the given token
   * @param {id} string
   * @returns {Promise<PreSignupDetails>}
   */

  fetchPreSignupDetails = async (token: string): Promise<PreSignupDetails> => {
    const headers = {
      Authorization: `Basic ${StringsHelper.encodeToB64(
        `${this.API_CLIENT_KEY}:${this.API_SECRET_KEY}`,
      )}`,
    };
    const apiCall = axios({
      headers,
      method: 'get',
      url: `/pre-signups/${token}`,
    });

    return this.executeApiCall(0, 'fetchPreSignupDetails', true, apiCall);
  };

  /**
   * Save New User in the system.
   *
   * @param data Details
   *
   * @returns {Promise<User>}
   */
  saveUser = async (data: SignupDetails): Promise<User> => {
    const headers = {
      Authorization: `Basic ${StringsHelper.encodeToB64(
        `${this.API_CLIENT_KEY}:${this.API_SECRET_KEY}`,
      )}`,
    };
    const apiCall = axios({
      headers,
      method: 'post',
      url: '/accounts',
      data,
    });

    return this.executeApiCall(0, 'saveUser', true, apiCall);
  };

  /**
   * Fetch all menu item options
   *
   * @param menuId: The menu identifier
   * @returns {Promise<void>}
   */
  fetchMenuItemOptions = async (
    merchantId: string,
  ): Promise<MenuItemOptions[]> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'get',
      url: `/merchants/${merchantId}/menu-options`,
    });
    return this.executeApiCall(0, 'fetchMenuItemOptions', true, apiCall);
  };

  /**
   * Fetch the menu item by menu item id and category id
   *
   * @param categoryId: The category identifier
   * @param menuItemId: Menu Item identifier
   * @returns {Promise<void>}
   */
  fetchMenuItem = async (menuItemId: string): Promise<MenuItem> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'get',
      url: `/menus/menuItem/${menuItemId}`,
    });
    return this.executeApiCall(0, 'fetchMenuItem', true, apiCall);
  };

  /**
   * Fetch the categories by menu id
   *
   * @param id: Menu identifier
   * @returns {Promise<void>}
   */
  fetchCategories = async (id: Opt<string>): Promise<MenuCategory[]> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'get',
      url: `/merchants/${id}/menu-categories`,
    });

    return this.executeApiCall(0, 'fetchCategoriesMenuById', true, apiCall);
  };

  /**
   * Fetch the categories by menu id
   *
   * @param id: Menu identifier
   * @returns {Promise<void>}
   */
  fetchGlobalSettings = async (settingsId: string) => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'get',
      url: `/settings/${settingsId}`,
    });

    return this.executeApiCall(0, 'fetchGlobalSettings', true, apiCall);
  };

  updateSettings = async ({ settingsId, data }: UpdateSettings) => {
    this.loadAccessToken();
    const apiCall = axios({
      method: 'patch',
      url: `/settings/${settingsId}`,
      data,
    });

    return this.executeApiCall(0, 'updateSettings', true, apiCall);
  };

  /**
   * Fetch All the menus
   *
   * @param key: The request query key
   * @param merchantId : string
   * @returns {Promise<void>}
   */
  fetchAllMenus = async (key: string, merchantId: string): Promise<Menu[]> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'get',
      url: `/merchants/${merchantId}/menus`,
    });

    return this.executeApiCall(0, key, true, apiCall);
  };

  /**
   * Fetch All the menus
   *
   * @param key: The request query key
   * @param merchantId : string
   * @returns {Promise<void>}
   */
  fetchAllMenuItems = async (
    key: string,
    merchantId: string,
  ): Promise<Menu[]> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'get',
      url: `/merchants/${merchantId}/menu-items`,
    });

    return this.executeApiCall(0, key, true, apiCall);
  };

  /**
   * Fetch the menu by its id
   *
   * @param id: Menu identifier
   * @returns {Promise<void>}
   */
  public async fetchMenu(id: string): Promise<Menu> {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'get',
      url: `/menus/${id}`,
    });

    return this.executeApiCall(0, 'fetchMenu', true, apiCall);
  }

  public fetchCouriers = async (merchantId: string) => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'get',
      url: `/merchants/${merchantId}/couriers`,
    });

    return this.executeApiCall(0, 'fetchCouriers', true, apiCall);
  };

  public refundOrder = async refundDetails => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'post',
      url: `/payments/${refundDetails.id}/refund`,
      params: {
        amount: refundDetails.amount,
      },
    });

    return this.executeApiCall(0, 'refundOrder', true, apiCall);
  };

  /**
   * Send the user a short code
   *
   * @param user: User object
   * @returns {Promise<void>}
   */
  public async sendShortCode(user: User): Promise<any> {
    try {
      const response = await axios({
        method: 'post',
        url: '/users/sendCode',
        data: user,
      });
      return response.data;
    } catch (error) {
      throw new ApiError(error);
    }
  }

  /**
   * Save a new upload
   *
   * @param upload: Upload object
   * @returns {Promise<void>}
   */
  public async saveUpload(url: string): Promise<any> {
    const apiCall = axios({
      method: 'post',
      url: `/uploads?url=${url}`,
    });
    return this.executeApiCall(0, 'saveUpload', true, apiCall);
  }

  /**
   * Upload an image by requesting a PUT url first to directly upload to it.
   *
   * @param file The image file uri path.
   * @param imageName The image file name.
   * @param type The upload type (i.e. USER_PHOTO, PLACE_PHOTO).
   * @returns {Promise<Upload>}
   */
  public async uploadImage(
    file: File,
    imageName: string,
    type: UploadType,
  ): Promise<PreSignedUrl> {
    const preApiCall = axios({
      method: 'get',
      url: '/uploads/presigned',
      params: {
        name: imageName,
        type,
        v2: 'true',
      },
    });
    const preResponse = await this.executeApiCall(
      0,
      'preSignedUrl',
      true,
      preApiCall,
    );

    return new Promise((resolve: any, reject: any) => {
      const { preSignedUrl, image, originalUrl } = preResponse;
      const xhr = new XMLHttpRequest();
      xhr.open('PUT', preSignedUrl);
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            // resolve(preResponse.second);
            resolve({
              image,
              originalUrl,
            });
          } else {
            reject(new Error('Error while sending the image to S3'));
          }
        }
      };
      xhr.setRequestHeader('Content-Type', 'image/jpeg');
      xhr.setRequestHeader('X-Safary-SessionId', this.sessionId);
      xhr.setRequestHeader('x-amz-acl', 'public-read');

      xhr.send(file);
    });
  }

  /**
   * Delete MenuX Category.
   *
   * @param id The Menu Category identifier
   * @returns {Promise<Array>}
   */
  deleteCategory = async (id: string): Promise<any> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'delete',
      url: `/menus/categories/${id}`,
    });

    return this.executeApiCall(0, 'deleteCategory', true, apiCall);
  };

  /**
   * Delete MenuX Item.
   *
   * @param id The Menu Item identifier
   * @returns {Promise<Array>}
   */
  deleteMenuItem = async (id: string): Promise<any> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'delete',
      url: `/menus/menuItem/${id}`,
    });

    return this.executeApiCall(0, 'deleteMenuItem', true, apiCall);
  };

  /**
   * Updates a menu.
   *
   * @param data The menu to update
   * @returns {Promise<Array>}
   */
  updateMenu = async (data: Menu): Promise<Menu> => {
    this.loadAccessToken();
    const apiCall = axios({
      method: 'put',
      url: '/menus',
      data,
    });

    return this.executeApiCall(0, 'updateMenuItem', true, apiCall);
  };

  /**
   * Update array of existing menus in the system.
   *
   * @param menusArray Array object with updated data

   * @returns Updated Store Object {Promise<StoreSettings[]>}
   */
  updateMenusArray = async (menusArr: Menu[]): Promise<Menu[]> => {
    return Promise.all(menusArr.map(menu => this.updateMenu(menu)));
  };

  /**
   * Updates MenuX Item.
   *
   * @param item The Menu Item
   * @returns {Promise<Array>}
   */
  updateMenuItem = async (item: MenuItem): Promise<MenuItem> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'patch',
      url: `/menus/item/${item?.id}`,
      data: item,
    });

    return this.executeApiCall(0, 'updateMenuItem', true, apiCall);
  };

  /**
   * Deletes MenuX Item Options.
   *
   * @param options The Menu Item Options
   * @returns {Promise<Array>}
   */
  public async deleteMenuItemOptions(
    options: MenuItemOptions[],
  ): Promise<MenuItem> {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'delete',
      url: '/menus/menuItem/options',
      data: options,
    });

    return this.executeApiCall(0, 'deleteMenuItemOptions', true, apiCall);
  }

  /**
   * Updates MenuX Item Options.
   *
   * @param options The Menu Item Options
   * @returns {Promise<Array>}
   */
  public async updateMenuItemOptions(
    options: MenuItemOptions[],
  ): Promise<MenuItem> {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'put',
      url: '/menus/menuItem/options',
      data: options,
    });

    return this.executeApiCall(0, 'updateMenuItemOptions', true, apiCall);
  }

  public updateSingleMenuItemOption = async ({
    optionId,
    menuItemOption,
  }: UpdateMenuItemPayload): Promise<MenuItemOptions> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'patch',
      url: `/menus/options/${optionId}`,
      data: menuItemOption,
    });
    return this.executeApiCall(0, 'updateSingleMenuItemOption', true, apiCall);
  };

  public addMenuItemOptions = async (
    menuItemOption: MenuItemOptions[],
  ): Promise<MenuItemOptions> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'post',
      url: '/menus/options',
      data: menuItemOption,
    });
    return this.executeApiCall(0, 'updateSingleMenuItemOption', true, apiCall);
  };

  fetchMenuItemOption = async (
    key: string,
    optionId: string,
  ): Promise<MenuItemOptions> => {
    this.loadAccessToken();
    const apiCall = axios({
      method: 'get',
      url: `/menus/options/${optionId}`,
    });
    return this.executeApiCall(0, 'fetchMenuItemOption', true, apiCall);
  };

  /**
   * Save MenuX Item.
   *
   * @param item The Menu Item
   * @returns {Promise<Array>}
   */
  createMenuItem = async (item: MenuItem | undefined): Promise<MenuItem> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'post',
      url: '/menus/menuItem',
      data: item,
    });

    return this.executeApiCall(0, 'createMenuItem', true, apiCall);
  };

  /**
   * Updates a Category.
   *
   * @param data The category
   * @returns {Promise<Array>}
   */
  updateCategory = async (data: MenuCategory): Promise<MenuCategory> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'patch',
      url: `/menus/category/${data.id}`,
      data,
    });

    return this.executeApiCall(0, 'updateCategory', true, apiCall);
  };

  /**
   * Saves a Category.
   *
   * @param data The category
   * @returns {Promise<Array>}
   */
  createCategory = async (data: MenuCategory): Promise<MenuCategory[]> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'post',
      url: '/menus/category',
      data,
    });

    return this.executeApiCall(0, 'createCategory', true, apiCall);
  };

  fetchCategoryById = async (id: string): Promise<MenuCategory> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'get',
      url: `/menus/category/${id}`,
    });

    return this.executeApiCall(0, 'fetchCategoryById', true, apiCall);
  };

  /**
   * Saves List of Categories.
   *
   * @param data The category
   * @returns {Promise<Array>}
   */
  createCategories = async (data: MenuCategory[]): Promise<MenuCategory[]> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'post',
      url: '/menus/categories',
      data,
    });

    return this.executeApiCall(0, 'createCategory', true, apiCall);
  };

  /**
   * Save MenuX Item Options.
   *
   * @param itemId The Menu Item Id
   * @param options The Menu Item Options
   * @returns {Promise<Array>}
   */
  saveMenuItemOptions = async ({
    itemId,
    options,
  }: {
    itemId: string;
    options: MenuItemOptions[];
  }): Promise<MenuItemOptions[]> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'post',
      url: `/menus/menuItem/${itemId}/options`,
      data: options,
    });

    return await this.executeApiCall(0, 'saveMenuItemOptions', true, apiCall);
  };

  /**
   * Fetch All Orders
   * This function fetches all the order for the given user
   *
   * @param key: string
   * @param merchantId: string
   * @param startDate: string
   * @param endDate: string
   * @param limit: number
   * @param startKey: string (Optional)
   *
   * @returns {Promise<OrderList>}
   */

  fetchOrders = async (
    key: string,
    merchantId: string,
    startDate: string,
    endDate: string,
    limit: number,
    sort: string,
    startKey?: string | unknown,
  ): Promise<OrdersData> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'get',
      url: `/merchants/${merchantId}/orders`,
      params: { startDate, endDate, limit, sort, startKey },
    });

    return this.executeApiCall(0, 'fetchOrders', true, apiCall);
  };

  /**
   * Search Orders
   * This function search and return order if it exists in the complete DB
   *
   * @param key The reacy query key
   * @param orderNumber:string
   * @returns {Promise<void>}
   */

  searchOrder = async (key: string, orderNumber: string): Promise<Order> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'get',
      url: `/orders/search`,
      params: { orderNumber },
    });

    return this.executeApiCall(0, key, true, apiCall);
  };

  fetchAccountOrders = async (
    key: string,
    startDate: string,
    endDate: string,
    limit: number,
    sort: string,
  ) => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'get',
      url: `/accounts/self/orders`,
      params: { startDate, endDate, limit, sort },
    });

    return this.executeApiCall(0, key, true, apiCall);
  };

  fetchAccountUsage = async (key: string, month: number, year: number) => {
    this.loadAccessToken();
    const apiCall = axios({
      method: 'get',
      url: `/accounts/self/usage`,
      params: { month, year },
    });

    return this.executeApiCall(0, key, true, apiCall);
  };

  /* Fetch Order Details
   * This function fetches the order details
   * @param {orderId}
   * @returns {Promise<Order>}
   */

  fetchOrderById = async (id: string): Promise<Order> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'get',
      url: `/v1/orders/${id}`,
    });

    return this.executeApiCall(0, 'fetchOrderById', true, apiCall);
  };

  /**
   * Complete the order
   *
   * @param orderId string

   * @returns {Promise<void>}
   */

  completeOrder = async (orderId: string): Promise<void> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'post',
      url: `/orders/${orderId}/complete`,
    });

    return this.executeApiCall(0, 'completeOrder', true, apiCall);
  };

  assignDriver = async ({
    orderId,
    courierId,
  }: AssignDriver): Promise<Courier> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'post',
      url: `/v1/orders/${orderId}/assign-courier`,
      params: { courierId },
    });

    return this.executeApiCall(0, 'assignDriver', true, apiCall);
  };

  /**
   * Accept the order
   *
   * @param acceptOrderEntry AcceptOrderEntry
   *
   * @returns {Promise<void>}
   */

  acceptOrder = async ({
    orderId,
    estimatedTime,
  }: AcceptOrderEntry): Promise<void> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'post',
      url: `/orders/${orderId}/accept`,
      params: { estimatedTime },
    });

    return this.executeApiCall(0, 'acceptOrder', true, apiCall);
  };

  inProgress = async (orderId: string): Promise<void> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'post',
      url: `/v1/orders/${orderId}/in-delivery`,
    });

    return this.executeApiCall(0, 'acceptOrder', true, apiCall);
  };

  /**
   * Reject the order
   *
   * @param rejectOrderEntry RejectOrderEntry obj
   *
   * @returns {Promise<void>}
   */

  rejectOrder = async ({
    orderId,
    reason,
  }: RejectOrderEntry): Promise<void> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'post',
      url: `/orders/${orderId}/reject`,
      params: { reason },
    });

    return this.executeApiCall(0, 'rejectOrder', true, apiCall);
  };

  /**
   * Updates user current user profile
   * @param data User
   * @returns {Promise<User>}
   */
  updateMyProfile = async (data: User): Promise<User> => {
    this.loadAccessToken();
    // save in new variable so we don't mutate readonly input

    const apiCall = axios({
      method: 'put',
      url: '/users/self',
      data,
    });
    const response = await this.executeApiCall(
      0,
      'updateMyProfile',
      true,
      apiCall,
    );
    return response;
  };

  /**
   * fetch Admin as a Account object
   *
   * @returns {Promise<Account>}
   */

  fetchAccount = async (): Promise<Account> => {
    this.loadAccessToken();
    const apiCall = axios({
      method: 'get',
      url: '/admins/self/account',
    });
    return this.executeApiCall(0, 'fetchAdmin', true, apiCall);
  };

  fetchVersion = async (): Promise<Version> => {
    this.loadAccessToken();
    delete axios.defaults.headers.common['X-Safary-SessionId'];
    delete axios.defaults.headers.common['X-Foodbit-SessionId'];
    const appVersion = process.env.REACT_APP_VERSION;
    const apiCall = axios({
      method: 'get',
      url: `https://applepay.juicycarrot.co/pos-app-min-version?version=${appVersion}&os=dashboard`,
    });
    return this.executeApiCall(0, 'fetchVersion', true, apiCall);
  };

  /**
   * Updates a merchant
   *
   * @param data The merchant to update
   * @returns {Promise<Merchant[]>}
   */
  updateMerchant = async (data: Merchant): Promise<Merchant> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'put',
      url: '/merchants',
      data,
    });

    return this.executeApiCall(0, 'updateMerchant', true, apiCall);
  };

  /**
   * Get All merchants
   *
   * @returns {Promise<Merchant[]>}
   */
  fetchMerchants = async (): Promise<Merchant[]> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'get',
      url: '/admin/self/merchants',
    });

    return this.executeApiCall(0, 'fetchMerchants', true, apiCall);
  };

  /**
   * Fetch All Customers
   * This function fetches all the customers for the given merchant
   *
   * @param key: string
   * @param merchantId: string
   * @param startDate: string
   * @param endDate: string
   * @param limit: number
   * @param startKey: string (Optional)
   *
   * @returns {Promise<Customers>}
   */

  fetchCustomers = async (
    key: string,
    merchantId: string,
    startDate: string,
    endDate: string,
    limit: number,
    sort: string,
    sortBy: string,
    startKey?: string | unknown,
  ): Promise<Customers> => {
    await this.loadAccessToken();

    const apiCall = axios({
      method: 'get',
      url: this.API_URL.concat(`/merchants/${merchantId}/customers`),
      params: { startDate, endDate, limit, sort, startKey, sortBy },
    });

    return this.executeApiCall(0, 'fetchCustomers', true, apiCall);
  };

  /**
   * Fetch Customer Details By ID
   *
   * @param id`: string
   * @returns {Promise<Customer>}
   */

  fetchCustomerById = async (
    customerId: string,
    merchantId: string,
  ): Promise<CustomerAPI> => {
    await this.loadAccessToken();

    const apiCall = axios({
      method: 'get',
      url: this.API_URL.concat(`/merchants/${merchantId}/customer-details`),
      params: { guestIds: customerId },
    });

    return this.executeApiCall(0, 'fetchCustomerById', true, apiCall);
  };

  /**
   * Fetch Specific Customer Orders
   * This function fetches all the order of customer for the given guestIds
   *
   * @param key: string
   * @param merchantId: string
   * @param startDate: string
   * @param endDate: string
   * @param limit: number
   * @param startKey: string (Optional)
   *
   * @returns {Promise<OrdersData>}
   */

  fetchCustomerOrders = async (
    key: string,
    merchantId: string,
    startDate: string,
    endDate: string,
    limit: number,
    sort: string,
    guestIds: string[],
    startKey?: string,
  ): Promise<OrdersData> => {
    await this.loadAccessToken();

    const encodedStartKey =
      startKey !== undefined
        ? encodeURIComponent(JSON.stringify(startKey).slice(1, -1))
        : '';

    const apiCall = axios({
      method: 'get',
      url: this.API_URL.concat(
        `/merchants/${merchantId}/customers/orders?startKey=${encodedStartKey}`,
      ),
      params: {
        startDate,
        endDate,
        limit,
        sort,
        guestIds: guestIds.join(','),
      },
    });

    return this.executeApiCall(0, 'fetchCustomerOrders', true, apiCall);
  };

  /**
   * Get the user form the backend
   *
   * @param id The user identifier.
   * @return {Promise<Object>}
   */
  public async fetchUser(email: string): Promise<User> {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'get',
      url: `/users/${email}`,
    });

    return this.executeApiCall(0, 'fetchUser', true, apiCall);
  }

  public async forgotPassword(email: string) {
    const apiCall = axios({
      method: 'get',
      url: `/users/password/reset?emailAddress=${email}`,
    });

    return this.executeApiCall(0, 'forgotPassword', true, apiCall);
  }

  /**
   * Reset password an order
   *
   * @param phoneNumber The phone number.
   * @return {Promise<Order>}
   */
  public async requestResetPassword(phoneNumber: string): Promise<any> {
    const apiCall = axios({
      method: 'get',
      url: '/users/password/reset',
      params: {
        phoneNumber,
      },
    });

    return this.executeApiCall(0, 'requestResetPassword', true, apiCall);
  }

  /**
   * Execute a given call. Before checking the call, check if the token has
   * expired and try to refresh it. Queues up the calls if the token is expired
   * until the token is refreshed.
   *
   * @param numberOfRetries The number of retries we have made so far for the @call
   * @param name Name of the api call.
   * @param needsAuth If true, the function will attach the access_token and refreshes it if needed.
   * @param call The api call.
   * @returns {Promise<void>}
   */
  public async executeApiCall(
    numberOfRetries: number,
    name: string,
    needsAuth: boolean,
    call: Promise<any>,
  ): Promise<any> {
    if (numberOfRetries >= 5) {
      throw Error('Reached max retries');
    }

    if (needsAuth && (await this.isTokenExpired())) {
      if (!this.refreshing) {
        await this.refreshToken();
      } else {
        // Wait for 2 seconds and try again
        await sleep(2000);
        return this.executeApiCall(numberOfRetries + 1, name, needsAuth, call);
      }
    }

    try {
      const response = await call;
      if (response.data) {
        return response.data;
      }
      return;
    } catch (error) {
      throw new ApiError(error);
    }
  }

  /**
   * True if there is an access token and false otherwise.
   *
   * @returns {boolean}
   */
  public isUserLoggedIn(): boolean {
    try {
      this.loadAccessToken();
      return this.token?.access_token !== '';
    } catch (e) {
      return false;
    }
  }

  /**
   * Logs out the user
   *
   */
  public async logout() {
    await MXCookies.reset();
  }
}

export default new Api();
// export { MXApi as TestApi };
export const sleep = async (ms: number) =>
  new Promise(resolve => setTimeout(resolve, ms));
