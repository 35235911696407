import { changeLanguage } from 'src/i18n/changeLanguage';
import { getPreferredLanguage } from 'src/i18n/getPreferredLanguage';
import { SupportedLanguage } from 'src/i18n/types';
import create from 'zustand';

interface Language {
  language: SupportedLanguage;
  setCurrentLanguage: (language: SupportedLanguage) => void;
}
const [useCurrentLanguageStore] = create<Language>(set => ({
  language: getPreferredLanguage(),
  setCurrentLanguage: (language: SupportedLanguage) => {
    changeLanguage(language);
    set({ language });
  },
}));

export const useCurrentLanguage = () => useCurrentLanguageStore();
