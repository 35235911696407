interface Strings {
  [key: string]: string;
}

interface Translations {
  en: Strings;
  ar: Strings;
}

interface Resource {
  namespace: string;
  translations: Translations;
}

export const formatResources = (resources: Resource[]) =>
  resources.reduce(
    (previousValue, currentValue) => {
      previousValue.en = {
        ...previousValue.en,
        [currentValue.namespace]: currentValue.translations.en,
      };
      previousValue.ar = {
        ...previousValue.ar,
        [currentValue.namespace]: currentValue.translations.ar,
      };
      return previousValue;
    },
    { en: {}, ar: {} },
  );
