import React from 'react';
import Api from 'src/api/Api';

import { LoginPage } from '../components/LoginPage';

export interface UserProp {
  user: any; // TODO user type
}

export const withAuth = <P extends UserProp>(Comp: React.ComponentType<P>) => (
  props: Subtract<P, UserProp>,
) => {
  if (Api.isUserLoggedIn()) {
    return <Comp {...(props as P)} />;
  } else {
    return <LoginPage />;
  }
};
