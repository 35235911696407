import { Button, CircularProgress } from '@material-ui/core';
import React from 'react';

interface Props {
  loading: boolean;
  form?: string;
}

export const PrimaryButton: React.FC<Props> = ({ loading, children, form }) => {
  return (
    <Button
      disabled={loading}
      type="submit"
      disableElevation
      style={{
        fontWeight: 900,
        color: '#fff',
        padding: '8px 50px',
      }}
      form={form}
      color="secondary"
      variant="contained">
      {loading && (
        <CircularProgress color="secondary" className="mr-2" size={18} />
      )}
      {children}
    </Button>
  );
};
