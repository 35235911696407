import {
  createStyles,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { Link } from '@reach/router';
import React, { memo } from 'react';
import { OPAQUE_PRIMARY_COLOR, SUCCESS_COLOR } from 'src/theme/consts';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      color: 'inherit',
      textDecoration: 'none',
      display: 'flex',
      '&[data-current=true]': {
        color: '#fff',
        backgroundColor: SUCCESS_COLOR,
      },
      '&[data-subLink=true]': {
        color: SUCCESS_COLOR,
        fontWeight: 900,
      },
    },

    expandedLink: {
      color: '#fff',
      backgroundColor: SUCCESS_COLOR,
      '&:hover': {
        color: '#fff',
        backgroundColor: SUCCESS_COLOR,
      },
    },

    icon: {
      color: SUCCESS_COLOR,
    },
    white: {
      color: '#fff',
    },
    subLink: {
      paddingLeft: 20,
    },
    paddedIcon: {
      color: 'inherit',
      paddingLeft: theme.spacing(2.5),
    },
    selectedListItem: {
      backgroundColor: OPAQUE_PRIMARY_COLOR,
    },
    iconButton: {
      padding: 6,
      marginRight: 5,
    },
  }),
);

interface Props {
  icon?: React.ReactElement;
  text: string;
  to?: string;
  onClick?: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => void | undefined;
  selected?: boolean;
  disableGutters?: boolean;
  collapsable?: boolean;
  isExpanded?: boolean;
  isSubLink?: boolean;
}

export const MenuLink = memo<Props>(
  ({ icon, text, to, onClick, collapsable, isExpanded, isSubLink }) => {
    const classes = useStyles();
    const linkRef = React.createRef<HTMLAnchorElement>();

    const Root = ({
      children,
      subLink,
    }: {
      children: React.ReactChild;
      subLink: boolean | undefined;
    }) =>
      to ? (
        <Link
          innerRef={linkRef}
          to={to}
          className={classes.link}
          getProps={({ isCurrent }) => {
            return {
              'data-subLink': isCurrent && subLink,
              'data-current': isCurrent && !subLink,
            };
          }}>
          {children}
        </Link>
      ) : (
        <div className={classes.link}>{children}</div>
      );

    return (
      <Root subLink={isSubLink}>
        <ListItem
          className={isExpanded ? classes.expandedLink : undefined}
          button
          disableGutters
          onClick={onClick}>
          <ListItemIcon className={classes.paddedIcon}>{icon}</ListItemIcon>
          <ListItemText
            className={isSubLink ? classes.subLink : undefined}
            primary={text}
          />

          <IconButton className={classes.iconButton}>
            {collapsable &&
              (isExpanded ? (
                <KeyboardArrowUp className={classes.white} />
              ) : (
                <>
                  {to === window.location.pathname ? (
                    <KeyboardArrowDown className={classes.white} />
                  ) : (
                    <KeyboardArrowDown className={classes.icon} />
                  )}
                </>
              ))}
          </IconButton>
        </ListItem>
      </Root>
    );
  },
);
